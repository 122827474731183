export const alerts = [
  { text: 'Cannibalization', value: 'alert_cannibalization' },
  { text: 'URL conflict', value: 'alert_url_conflict' },
  { text: 'No keywords mentioned', value: 'alert_no_keywords_mentioned' }
]
export const actions = [
  { text: 'Leave as-is', value: 'leave' },
  { text: 'Merge', value: 'merge' },
  { text: 'Delete', value: 'delete' },
  { text: 'Update', value: 'update' },
  { text: 'Not enough data', value: 'not-enough-data' }
]
export const statuses = [
  { text: 'No status', value: 'no status', color: '#FFFFFF' }, // White
  { text: 'Briefed', value: 'briefed', color: 'green lighten-5' }, // Light Green
  { text: 'Researched', value: 'researched', color: 'green lighten-4' }, // Lighter Green
  { text: 'Drafted', value: 'drafted', color: 'green lighten-3' }, // Light Green
  { text: 'Edited', value: 'edited', color: 'green lighten-2' }, // Light Green
  { text: 'Illustrated', value: 'illustrated', color: 'green lighten-1' }, // Light Green
  { text: 'Shared', value: 'shared', color: 'green lighten-1' }, // Light Green
  { text: 'Reviewed', value: 'reviewed', color: 'green' }, // Green
  { text: 'Published', value: 'published', color: 'green darken-1' } // Dark Green
]
export const priorities = [
  { text: 'High', value: 'high' },
  { text: '-', value: 'not set' },
  { text: 'Low', value: 'low' }
]
export const funnel = [
  { text: 'Top', value: 'top', color: '#FFF9C4' }, // Light Yellow
  { text: 'Middle', value: 'middle', color: '#FFF59D' }, // Medium Light Yellow
  { text: 'Bottom', value: 'bottom', color: '#FFEB3B' } // Darker Yellow
]
export const types = [
  { text: 'Glossary', value: 'glossary', color: '#FFB3BA' }, // Pastel Red
  { text: 'Article', value: 'article', color: '#FFDFBA' }, // Pastel Orange
  { text: 'Asset', value: 'asset', color: '#FFFFBA' }, // Pastel Yellow
  { text: 'Listicle', value: 'listicle', color: '#BAFFC9' }, // Pastel Green
  { text: 'Buyers guide', value: 'buyers-guide', color: '#BAE1FF' }, // Pastel Blue
  { text: 'Category', value: 'category', color: '#E0BAFF' }, // Pastel Purple
  { text: 'Product', value: 'product', color: '#FFB3E6' }, // Pastel Pink
  { text: 'Home', value: 'home', color: '#FFDAC1' } // Pastel Peach
]
export const intents = [
  { text: 'Informational', value: 'informational' },
  { text: 'Transactional', value: 'transactional' },
  { text: 'Navigational', value: 'navigational' }
]

export default {
  setAuthToken: (state, token) => {
    state.token = token

    if (token) {
      window.localStorage.setItem('jwt', token)
    } else {
      window.localStorage.removeItem('jwt')
    }
  },

  setSidebar: (state, show) => {
    state.showSidebar = show
  },

  updateWorkspaces: (state, workspaces) => {
    state.workspaces = workspaces
  },

  updateActiveWorkspace: (state, workspace) => {
    state.activeWorkspace = workspace
  },

  updateContentplans: (state, contentplans) => {
    state.contentplans = contentplans
  },

  updateActiveContentplan: (state, contentplan) => {
    state.activeContentplan = contentplan
  },

  updateContentplanKeywords: (state, keywords) => {
    state.keywords = keywords
  },

  updatePageKeywords: (state, keywords) => {
    state.pageKeywords = keywords
  },

  updateRelatedKeywords: (state, keywords) => {
    state.keywords.related = keywords
  },

  updateArticleKeywords: (state, keywords) => {
    state.keywords = keywords
  },

  updateArticleUrls: (state, urls) => {
    state.articleUrls = urls
  },

  updateExternalUrls: (state, urls) => {
    state.articleExternalUrls = urls
  },

  updateScore: (state, score) => {
    state.score = score
  },

  loadTopics: (state, topics) => {
    state.topics = topics
  },

  setArticles: (state, articles) => {
    state.articles = articles
  },

  loadContentplanInternalURLs: (state, urls) => {
    state.internalUrls = urls
  },

  loadArticle: (state, article) => {
    state.article = article
  },

  loadTopicArticles: (state, response) => {
    state.topic.articles = response.articles
    state.topic.articlesRemaining = response.articlesRemaining
  },

  loadMoreTopicArticles: (state, response) => {
    state.topic.articles.push(...response.articles)
    state.topic.articlesRemaining = response.articlesRemaining
  },

  updateGSCDataByDate: (state, gscData) => {
    state.gsc.byDate = gscData
  },

  updateGSCDataByQuery: (state, gscData) => {
    state.gsc.byQuery = gscData
  },

  updateGSCDataByPage: (state, gscData) => {
    state.gsc.byPage = gscData
  },

  updatePages: (state, pages) => {
    state.pages = pages
  },

  loadPageArticles: (state, articles) => {
    state.pageArticles = articles
  },

  // INTERNAL LINKS
  updateInternalLinks: (state, links) => {
    state.internalLinks = links
  },

  // CONTENTPLAN
  renameContentplan: (state, { contentplanId, name }) => {
    state.contentplans = state.contentplans.map(c => {
      if (c.id === contentplanId) {
        c.name = name
      }
      return c
    })
  },

  // TOPIC
  topicNew: (state, { topicId, name, contentplanId }) => {
    state.topics.push({
      id: topicId,
      name,
      contentplan_id: contentplanId
    })
  },

  topicRename: (state, { topicId, name }) => {
    state.topics = state.topics.map(t => {
      if (t.id === topicId) {
        t.name = name
      }
      return t
    })
  },

  topicExclude: (state, { topicIds }) => {
    state.topics = state.topics.filter(t => !topicIds.includes(t.id))
  },

  // PAGES
  pageExclude: (state, { pageIds }) => {
    state.pages = state.pages.filter(p => !pageIds.includes(p.page_id))
  },

  pageMatchLock: (state, { pageIds }) => {
    state.pages = state.pages.map(p => {
      if (pageIds.includes(p.page_id)) {
        p.article_id_locked = !p.article_id_locked
      }
      return p
    })
  },

  pageMatchUpdate: (state, { pageId, articleId }) => {
    state.pages = state.pages.map(p => {
      if (p.page_id === pageId) {
        p.article_id = articleId
        p.article_id_locked = true
      }
      return p
    })
  },

  // ARTICLES
  loadArticlesAdvanced: (state, articles) => {
    state.articles = articles.articles
    state.keywords = articles.keywords
    state.articleCounts = articles.counts
  },

  articleExclude: (state, { articleIds }) => {
    state.articles = state.articles.filter(a => !articleIds.includes(a.id))
  },

  articleRename: (state, { articleId, title }) => {
    state.articles = state.articles.map(a => {
      if (a.id === articleId) {
        a.title = title
      }
      return a
    })
  },

  articleMove: (state, { articleIds, topicId }) => {
    state.articles = state.articles.map(a => {
      if (articleIds.includes(a.id)) {
        a.topic_id = topicId
      }
      return a
    })
  },

  articleChangeTag: (state, { articleIds, tag, newValue }) => {
    state.articles = state.articles.map(a => {
      if (articleIds.includes(a.id)) {
        a[tag] = newValue
      }
      return a
    })
  },

  articleNew: (state, { articleId, title, topicId, contentplanId }) => {
    state.articles.push({
      id: articleId,
      title,
      topic_id: topicId,
      contentplan_id: contentplanId
    })
  },

  articleUpdateMainKeyword: (state, { articleId, keywordId, keyword }) => {
    // console.log('articleUpdateMainKeyword', articleId, keywordId)
    state.articles = state.articles.map(a => {
      if (a.id === articleId) {
        a.main_keyword_id = keywordId
        a.title = keyword
      }
      return a
    })
  },

  // KEYWORDS
  keywordExclude: (state, { keywordIds }) => {
    state.keywords = state.keywords.map(k => {
      if (keywordIds.includes(k.id)) {
        k.excluded = true
        k.article_id = null
      }
      return k
    })
  },

  keywordMoveCluster: (state, { keywordIds, articleId }) => {
    state.keywords = state.keywords.map(k => {
      if (keywordIds.includes(k.id)) {
        k.article_id = articleId
      }
      return k
    })
  },

  integrations: (state, data) => {
    state.integrations = data
  },

  activeIntegration: (state, data) => {
    state.activeIntegration = data
  },

  setSnackbar: (state, data) => {
    state.snackbar = { ...data }
  },

  // Websocket mutations
  setWebsocket: (state, socket) => {
    state.websocket.instance = socket
  },

  setWebsocketConnected: (state, connected) => {
    state.websocket.connected = connected
  },

  incrementReconnectAttempts: (state) => {
    state.websocket.reconnectAttempts++
  },

  resetReconnectAttempts: (state) => {
    state.websocket.reconnectAttempts = 0
  },

  addWebsocketMessage: (state, message) => {
    state.websocket.messages.push(message)
  },

  clearWebsocketMessages: (state) => {
    state.websocket.messages = []
  },

  // PROGRESS TRACKERS
  addProgressTracker: (state, tracker) => {
    console.log('addProgressTracker', tracker)
    state.progressTrackers.push(tracker)
  },

  updateProgressTracker: (state, { contentplanId, clusterId, stepName, status }) => {
    console.log('updateProgressTracker', contentplanId, clusterId, stepName, status)
    let trackerIndex
    if (clusterId) trackerIndex = state.progressTrackers.findIndex(tracker => tracker.clusterId === clusterId)
    else trackerIndex = state.progressTrackers.findIndex(tracker => tracker.contentplanId === contentplanId)

    if (trackerIndex !== -1) state.progressTrackers[trackerIndex].stepsObject[stepName] = status
  },

  removeProgressTracker: (state, { contentplanId, clusterId }) => {
    if (clusterId) state.progressTrackers = state.progressTrackers.filter(tracker => tracker.clusterId !== clusterId)
    else state.progressTrackers = state.progressTrackers.filter(tracker => tracker.contentplanId !== contentplanId)
  },

  clearProgressTrackers: (state) => {
    state.progressTrackers = []
  },

  updateWorkspaceUsers: (state, users) => {
    state.workspaceUsers = users
  },

  // AUTOBLOG
  setAutoblogSettings: (state, settings) => {
    state.autoblogSettings = settings
  }
}

import { mapGetters, mapActions, mapMutations } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'

export default {
  name: 'Map',
  data () {
    return {
      isLoading: {
        addItem: false,
        addHub: false,
        renameTopic: false,
        removeItem: false,
        deleteTopic: false,
        writeAll: false,
        changeTopic: false,
        changeFunnel: false
      },

      newItem: null,

      hubDialog: {
        show: false,
        name: '',
        description: '',
        id: null,
        isNew: false,
        loading: false
      },

      editMode: false,
      draggedArticle: null,
      currentDropTarget: null,
      funnels: ['top', 'middle', 'bottom'],
      mappedArticles: [],
      autocompleteArticles: [],
      timeout: null,
      searchArticle: null,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: 'success',
      chipColors: [
        { id: 'leave', name: 'Leave as-is', color: 'green lighten-4' },
        { id: 'update', name: 'Update', color: 'orange lighten-4' },
        { id: 'new', name: 'New', color: 'gray lighten-4' },
        { id: 'in_progress', name: 'In progress', color: 'blue lighten-4' }
      ],
      showWriteConfirmation: false,
      shortlistArticles: [],
      shortListTableHeaders: [
        { text: 'Cluster', value: 'title' },
        { text: 'Current status', value: 'status' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan?.name ?? ''
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadArticles',
      'loadTopics',
      'updateArticles',
      'addTopic',
      'updateTopics',
      'sendWebsocketMessage'
    ]),

    ...mapMutations([
      'topicRename',
      'setArticles',
      'addProgressTracker'
    ]),

    // RENDER DATA
    async loadData () {
      try {
        this.isLoading.articles = true
        document.title = 'Funnel map | ContentGecko'

        await Promise.all([
          this.loadArticles({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filterHasTopic: true,
            filterHasFunnel: true,
            filterIsMapped: true
          }),
          this.loadTopics({
            contentplanId: this.contentplanId
          })
        ])
        this.mappedArticles = this.articles
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.articles = false
      }
    },
    async addItem (parentItem, newItem, funnel) {
      this.isLoading.addItem = true
      const articleIds = [newItem]
      const topicId = parentItem.id

      try {
        await Promise.all([
          this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'updateTopic',
            articleIds,
            topicId
          }),
          this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'updateFunnel',
            articleIds,
            funnel
          }),
          this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'addToMap',
            articleIds
          })
        ])
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.addItem = false
      this.showAddItem = false
      this.newItem = null
    },
    async removeItem (itemToRemove) {
      const articleIds = [itemToRemove.article_id]
      this.isLoading.removeItem = itemToRemove.article_id
      console.log('removeItem', articleIds)

      try {
        await this.updateArticles({
          contentplanId: this.contentplanId,
          action: 'removeFromMap',
          articleIds
        })
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.removeItem = false
    },
    toggleEdit (topic) {
      console.log('toggleEdit', topic)

      if (this.showEdit === topic.id) {
        this.showEdit = null
      } else {
        this.showEdit = topic.id
      }
    },
    getTopicArticles (topic, funnel) {
      if (!this.articles?.length) return
      const topicId = topic?.id

      return this.articles.filter(a => a.funnel === funnel && a.topic_id === topicId && a.show_in_map)
    },
    async addHub () {
      console.log('addHub')
      this.hubDialog = {
        show: true,
        name: 'New hub',
        description: '',
        id: null,
        isNew: true,
        loading: false
      }
    },
    async saveHub () {
      this.hubDialog.loading = true
      try {
        if (this.hubDialog.isNew) {
          // Create new hub
          await this.addTopic({
            contentplanId: this.contentplanId,
            name: this.hubDialog.name,
            description: this.hubDialog.description
          })
        } else {
          // Update existing hub
          await this.updateTopics({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            topicIds: [this.hubDialog.id],
            name: this.hubDialog.name,
            action: 'rename'
          })

          await this.updateTopics({
            contentplanId: this.contentplanId,
            topicIds: [this.hubDialog.id],
            description: this.hubDialog.description,
            action: 'updateDescription'
          })

          // Update local state for the name
          this.topicRename({
            topicId: this.hubDialog.id,
            name: this.hubDialog.name
          })
        }

        await this.loadData()
        this.hubDialog.show = false
      } catch (error) {
        console.log(error)
        this.showFeedback('Failed to save hub', 'error')
      } finally {
        this.hubDialog.loading = false
      }
    },
    editHub (topic) {
      this.hubDialog = {
        show: true,
        name: topic.name,
        description: topic.description || '',
        id: topic.id,
        isNew: false,
        loading: false
      }
    },
    async deleteTopic (topicId) {
      if (!topicId) return
      this.isLoading.deleteTopic = topicId

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds: topicId,
          action: 'delete'
        })
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.deleteTopic = false
    },
    getChipColor (article) {
      // PLANNED - Purple if article has deadline and status is not published
      if (article.deadline && article.status !== 'published') return this.chipColors.find(c => c.id === 'in_progress').color
      // NEW PAGE - Blue if article has no url and is not empty
      if (!article.article_url && !article.empty) return this.chipColors.find(c => c.id === 'new').color
      // EXISTING PAGE
      if (article.article_url && !article.empty) {
        const action = article.action || article.suggested_action
        if (action === 'leave') return this.chipColors.find(c => c.id === 'leave').color
        return this.chipColors.find(c => c.id === 'update').color
      }
    },
    async loadAutocompleteArticles (searchString) {
      clearTimeout(this.timeout) // Clear previous timeout
      this.timeout = setTimeout(async () => {
        if (!this.searchArticle) return

        this.isLoading.addItem = true

        try {
          this.autocompleteArticles = await this.loadArticles({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            filterTitle: searchString,
            filterNotMapped: true,
            straightReturn: true
          })
        } catch (error) {
          console.log(error)
        }

        this.isLoading.addItem = false
      }, 500)
    },
    async handleWriteAll () {
      // Calculate shortlist
      const shortlist = this.articles.filter(a => a.status !== 'published' && a.intent !== 'transactional')
      this.shortlistArticles = shortlist
      this.showWriteConfirmation = true
    },
    async processWriteAll () {
      this.showWriteConfirmation = false
      this.isLoading.writeAll = true

      // Add progress tracker for each article
      this.shortlistArticles.forEach((article) => {
        this.addProgressTracker({
          contentplanId: this.contentplanId,
          clusterId: article.article_id,
          name: article.title,
          stepsObject: {
            Briefing: 'inProgress',
            Researching: 'pending',
            Drafting: 'pending',
            Editing: 'pending'
          }
        })
      })

      try {
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            runUpdating: false,
            runGrouping: false,
            runMapping: false,
            runWriting: true,
            selectBy: 'shortlist',
            publishTo: this.activeContentplan.main_cms_integration || 'autoblog',
            userType: 'managed'
          }
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.writeAll = false
    },
    handleDragStart (event, article) {
      // Set data immediately
      this.draggedArticle = article

      // Use a minimal data payload
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('text/plain', JSON.stringify({
        articleId: article.article_id,
        currentTopic: article.topic_id,
        currentFunnel: article.funnel
      }))

      // Add styling directly without setTimeout
      event.target.classList.add('dragging')

      // Use a short timeout for the feedback to not block the drag
      setTimeout(() => {
        this.showFeedback(`Dragging: ${article.title === '-' ? article.article_url : article.title}`, 'info')
      }, 10)
    },
    handleDragEnd () {
      // Clear state immediately
      this.draggedArticle = null
      this.currentDropTarget = null

      // Remove styling
      document.querySelectorAll('.dragging').forEach(el => {
        el.classList.remove('dragging')
      })
    },
    handleDragOver (event, topicId, funnel) {
      // Set the effect immediately
      event.dataTransfer.dropEffect = 'move'

      // Only update the drop target if it's different
      if (!this.currentDropTarget ||
          this.currentDropTarget.topicId !== topicId ||
          this.currentDropTarget.funnel !== funnel) {
        this.currentDropTarget = { topicId, funnel }
      }
    },
    handleDragEnter (event, topicId, funnel) {
      // Set active drop target
      this.currentDropTarget = { topicId, funnel }
      event.currentTarget.classList.add('active-drop-target')
    },
    handleDragLeave (event) {
      event.currentTarget.classList.remove('active-drop-target')
    },
    async handleDrop (event, topicId, funnel) {
      console.log('handleDrop', topicId, funnel)

      // Get data synchronously
      let data
      try {
        data = JSON.parse(event.dataTransfer.getData('text/plain'))
      } catch (e) {
        console.error('Error parsing drag data:', e)
        return
      }

      const articleId = data.articleId
      const currentTopic = data.currentTopic
      const currentFunnel = data.currentFunnel

      // Remove drop styling immediately
      event.currentTarget.classList.remove('active-drop-target')
      this.currentDropTarget = null

      // Only process if there's a change
      if (currentTopic === topicId && currentFunnel === funnel) {
        this.showFeedback('Article is already in this position', 'info')
        return
      }

      // Rest of the method stays the same but with optimistic updates
      try {
        // Get the article from the current state
        const article = this.articles.find(a => a.article_id === articleId)
        if (!article) return

        // Optimistic UI update - update local state immediately
        // Create a shallow copy to avoid mutating the original array
        const updatedArticles = this.articles.map(a => {
          if (a.article_id === articleId) {
            // Create a new object with the updated properties
            return {
              ...a,
              topic_id: topicId,
              funnel
            }
          }
          return a
        })

        // Manually update the UI state
        this.setArticles(updatedArticles)

        // Show feedback immediately
        this.showFeedback('Moving article...', 'info')

        // Setup API calls
        const changes = []

        // Change topic if needed
        if (currentTopic !== topicId) {
          this.isLoading.changeTopic = true
          changes.push(this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'updateTopic',
            articleIds: [articleId],
            topicId
          }))
        }

        // Change funnel if needed
        if (currentFunnel !== funnel) {
          this.isLoading.changeFunnel = true
          changes.push(this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'updateFunnel',
            articleIds: [articleId],
            funnel
          }))
        }

        // Execute API calls in the background
        if (changes.length > 0) {
          Promise.all(changes)
            .then(() => {
              this.showFeedback('Article moved successfully', 'success')
              // Refresh data in the background to ensure sync with server
              this.loadData().catch(err => console.error('Error refreshing data:', err))
            })
            .catch(error => {
              console.error('Error moving article:', error)
              this.showFeedback('Failed to move article - refreshing data', 'error')
              // Reload data to restore correct state
              this.loadData().catch(err => console.error('Error refreshing data:', err))
            })
            .finally(() => {
              this.isLoading.changeTopic = false
              this.isLoading.changeFunnel = false
            })
        }
      } catch (error) {
        console.error('Error in drag and drop operation:', error)
        this.showFeedback('An error occurred', 'error')
        this.isLoading.changeTopic = false
        this.isLoading.changeFunnel = false
      }
    },
    isDragging (article) {
      return this.draggedArticle && this.draggedArticle.article_id === article.article_id
    },
    isDropTarget (topicId, funnel) {
      return this.currentDropTarget &&
             this.currentDropTarget.topicId === topicId &&
             this.currentDropTarget.funnel === funnel
    },
    showFeedback (message, color = 'success') {
      this.snackbarMessage = message
      this.snackbarColor = color
      this.showSnackbar = true
    }
  },
  async mounted () {
    await this.loadData()
  }
}

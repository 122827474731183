import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login/Login.vue'
import LoginManaged from '@/views/LoginManaged/LoginManaged.vue'
import Auth from '@/views/Auth/Auth.vue'
import Home from '@/views/Home/Home.vue'
import Workspace from '@/views/Workspace/Workspace.vue'
import Keywords from '@/views/Keywords/Keywords.vue'
import CreateContentplan from '@/views/CreateContentplan/CreateContentplan.vue'
import Article from '@/views/Article/Article.vue'
import ArticleShared from '@/views/ArticleShared/ArticleShared.vue'
import Plan from '@/views/Plan/Plan.vue'
import Hubs from '@/views/Hubs/Hubs.vue'
import HubsNew from '@/views/HubsNew/HubsNew.vue'
import NewArticle from '@/views/NewArticle/NewArticle.vue'
import PageKwMapper from '@/views/PageKwMapper/PageKwMapper.vue'
import Pages from '@/views/Pages/Pages.vue'
import Report from '@/views/Report/Report.vue'
import Sprints from '@/views/Sprints/Sprints.vue'
import Mindmap from '@/views/Mindmap/Mindmap.vue'
import RoiCalculator from '@/views/RoiCalculator/RoiCalculator.vue'
import ContentplanSettings from '@/views/ContentplanSettings/ContentplanSettings.vue'
import WordpressIntegration from '@/views/Integrations/WordpressIntegration/WordpressIntegration.vue'
import GoogleAnalyticsIntegration from '@/views/Integrations/GoogleAnalyticsIntegration/GoogleAnalyticsIntegration.vue'
import SearchConsoleIntegration from '@/views/Integrations/SearchConsoleIntegration/SearchConsoleIntegration.vue'
import NotionIntegration from '@/views/Integrations/NotionIntegration/NotionIntegration.vue'
import Director from '@/views/Director/Director.vue'
import InternalLinks from '@/views/InternalLinks/InternalLinks.vue'
import ClustererFree from '@/views/ClustererFree/ClustererFree.vue'
import ClustererPaid from '@/views/ClustererPaid/ClustererPaid.vue'
import WriterFree from '@/views/WriterFree/WriterFree.vue'
import WriterPaid from '@/views/WriterPaid/WriterPaid.vue'
import ResearchPaid from '@/views/ResearchPaid/ResearchPaid.vue'
// import SaaSSemanticClusterer from '@/views/SaaSSemanticClusterer/SaaSSemanticClusterer.vue'
import WorkspaceSettings from '@/views/WorkspaceSettings/WorkspaceSettings.vue'
import StripeLander from '@/views/StripeLander/StripeLander.vue'
import ContentEditor from '@/views/ContentEditor/ContentEditor.vue'
import AISettings from '@/views/AISettings/AISettings.vue'
import StyleGuideSettings from '@/views/StyleGuideSettings/StyleGuideSettings.vue'
import AboutSettings from '@/views/AboutSettings/AboutSettings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/managed',
    name: 'LoginManaged',
    component: LoginManaged
  },
  {
    path: '/auth/:action',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/workspace',
    name: 'WorkspaceList',
    component: Workspace
  },
  {
    path: '/workspace/:workspaceId',
    name: 'Workspace',
    component: Workspace
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/keywords',
    name: 'Keywords',
    component: Keywords
  },
  {
    path: '/workspace/:workspaceId/contentplan/add',
    name: 'CreateContentplan',
    component: CreateContentplan
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/add',
    name: 'CreateContentplanStep2',
    component: CreateContentplan
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/article/:articleId',
    name: 'Article',
    component: Article
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/article/:articleId/:sharingKey',
    name: 'sharedArticle',
    component: ArticleShared
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/plan/:filter?',
    name: 'Plan',
    component: Plan
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/hubs',
    name: 'Hubs',
    component: Hubs
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/hubs-new',
    name: 'HubsNew',
    component: HubsNew
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/new-article',
    name: 'NewArticle',
    component: NewArticle
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/page-keyword-mapper',
    name: 'PageKwMapper',
    component: PageKwMapper
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/pages',
    name: 'Pages',
    component: Pages
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/sprints',
    name: 'Sprints',
    component: Sprints
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/map',
    name: 'Mindmap',
    component: Mindmap
  },
  {
    path: '/roi-calculator',
    name: 'RoiCalculator',
    component: RoiCalculator
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/settings',
    name: 'ContentplanSettings',
    component: ContentplanSettings
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/wordpress-integration',
    name: 'WordpressIntegration',
    component: WordpressIntegration
  },
  {
    path: '/notion-integration',
    name: 'NotionIntegration',
    component: NotionIntegration
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/ga-integration',
    name: 'GoogleAnalyticsIntegration',
    component: GoogleAnalyticsIntegration
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/gsc-integration',
    name: 'SearchConsoleIntegration',
    component: SearchConsoleIntegration
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/director',
    name: 'Director',
    component: Director
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/director/:sharingKey',
    name: 'DirectorShared',
    component: Director
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/internal-links',
    name: 'InternalLinks',
    component: InternalLinks
  },
  {
    path: '/clusterer',
    name: 'ClustererFree',
    component: ClustererFree
  },
  {
    path: '/workspace/:workspaceId/ultimate-clusterer',
    name: 'ClustererPaid',
    component: ClustererPaid
  },
  {
    path: '/ai-writer-free',
    name: 'WriterFree',
    component: WriterFree
  },
  {
    path: '/workspace/:workspaceId/ai-writer',
    name: 'WriterPaid',
    component: WriterPaid
  },
  {
    path: '/workspace/:workspaceId/keyword-research',
    name: 'ResearchPaid',
    component: ResearchPaid
  },
  // {
  //   path: '/workspace/:workspaceId/semantic-clusterer',
  //   name: 'SaaSSemanticClusterer',
  //   component: SaaSSemanticClusterer
  // },
  {
    path: '/workspace/:workspaceId/settings',
    name: 'WorkspaceSettings',
    component: WorkspaceSettings
  },
  {
    path: '/workspace/:workspaceId/stripe-success',
    name: 'StripeLander',
    component: StripeLander
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/content-editor',
    name: 'ContentEditor',
    component: ContentEditor
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/ai-settings',
    name: 'AISettings',
    component: AISettings
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/style-guide',
    name: 'StyleGuideSettings',
    component: StyleGuideSettings
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/about',
    name: 'AboutSettings',
    component: AboutSettings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { mapActions, mapGetters, mapMutations } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'
import SaaSCTA from '@/components/SaaSCTA/SaaSCTA.vue'
import keywordExclusionRegex from '@/utils/keywordExclusionRegex.js'

export default {
  name: 'WriterPaid',
  data () {
    return {
      isLoading: {
        contentplans: false,
        clusterer: false,
        writer: false,
        articles: false
      },

      keywords: null,
      keywordRules: [
        value => value?.length <= 80 || 'Keyword must be 80 characters or less',
        value => value?.split(' ').length <= 10 || 'Keyword must be 10 words or less',
        value => !new RegExp(keywordExclusionRegex.regex).test(value) || 'Keyword contains invalid characters'
      ],
      domain: null,
      domainRules: [
        // value can be empty
        value => !value || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,}\/?$/.test(value) || 'Invalid domain'
      ],

      countries,
      country: 'USA',
      languages,
      language: 'en',
      serpOverlap: 2,

      interval: null,
      tableHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Slug', value: 'slug' },
        { text: 'Type', value: 'type' },
        { text: 'Created', value: 'status_updated_at' }
      ],
      clusters: null,
      newContentplanId: null,
      newArticleId: null,
      selectedArticle: null,
      selectedArticleContent: null
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspace',
      'contentplans',
      'websocketUrl',
      'articles',
      'websocketConnected',
      'websocketInstance'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    hasActiveSubscription () {
      if (!this.activeWorkspace) return false
      const activeSubscription = this.activeWorkspace.type === 'saas' && this.activeWorkspace.subscription_active
      if (activeSubscription) this.keywordLimit = this.keywordLimitPaid
      return activeSubscription
    },
    hasActiveTrial () {
      if (!this.activeWorkspace) return false
      const workspaceCreated = new Date(this.activeWorkspace.created_at).getTime() // in UTC
      const now = new Date().getTime() + new Date(new Date().getTimezoneOffset() * 60 * 1000).getTime() // time now in UTC
      const trialLength = 24 * 60 * 60 * 1000 // 24 hours
      const activeTrial = this.activeWorkspace.type === 'saas' && !this.activeWorkspace.subscription_active && now - workspaceCreated < trialLength
      if (this.showTrialPopup === null) this.showTrialPopup = activeTrial
      return activeTrial
    }
  },
  methods: {
    ...mapActions([
      'loadArticlesSaas',
      'sendWebsocketMessage',
      'createContentplan',
      'addArticle'
    ]),
    ...mapMutations([
      'addProgressTracker',
      'updateProgressTracker',
      'removeProgressTracker'
    ]),
    async loadData () {
      this.isLoading.contentplans = true
      try {
        await this.loadArticlesSaas({
          workspaceId: this.workspaceId
        })
      } catch (e) {
        console.error(e)
      }

      this.isLoading.contentplans = false
    },
    async runClusterer () {
      this.isLoading.writer = true
      try {
        if (!this.keywords?.length) return
        const articleTitle = this.keywords

        // Add progress tracker for keyword research
        this.addProgressTracker({
          contentplanId: this.newContentplanId,
          clusterId: articleTitle,
          name: 'Preparing for writing',
          stepsObject: {
            KeywordResearch: 'inProgress',
            Clustering: 'pending'
          }
        })
        this.isLoading.writer = false

        const contentplanData = await this.createContentplan({
          name: 'AI Writer - ' + articleTitle,
          workspaceId: this.workspaceId,
          language: this.language,
          country: this.country,
          type: 'saas-writer'
        })
        this.newContentplanId = contentplanData.contentplanId
        this.updateProgressTracker({
          contentplanId: this.newContentplanId,
          clusterId: articleTitle,
          stepName: 'KeywordResearch',
          status: 'done'
        })

        const articleData = await this.addArticle({
          contentplanId: this.newContentplanId,
          name: articleTitle
        })
        this.newArticleId = articleData.article_id
        this.removeProgressTracker({ clusterId: articleTitle })

        // Add progress tracker for article
        this.addProgressTracker({
          contentplanId: this.newContentplanId,
          clusterId: this.newArticleId,
          name: articleTitle,
          stepsObject: {
            Briefing: 'inProgress',
            Researching: 'pending',
            Drafting: 'pending',
            Editing: 'pending'
          }
        })

        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.newContentplanId,
            clusterIds: [this.newArticleId],
            runUpdating: false,
            runGrouping: false,
            runMapping: false,
            runWriting: true,
            selectBy: 'cluster'
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    openArticle (article) {
      this.selectedArticle = article // Set the selected article
      this.selectedArticleContent = article.final // Set the selected article content
    },
    copyArticle (article) {
      if (!article) return

      const markdown = article.final
      // Add the markdown to the clipboard
      navigator.clipboard.writeText(markdown)
    },
    async closeEditor () {
      this.selectedArticle = null // Close the editor by resetting the selected article
      this.selectedArticleContent = null // Reset the selected article content
      await this.loadData() // Reload
    }
  },
  async mounted () {
    await this.loadData()

    // Listen for progress tracker completion event
    this.$root.$on('progress-tracker-completed', () => {
      // Call loadData when all steps are completed
      this.loadData()
    })
  },
  beforeDestroy () {
    // Clean up event listener
    this.$root.$off('progress-tracker-completed')
  },
  components: {
    MarkdownEditor,
    SaaSCTA
  }
}

import { mapActions, mapGetters } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'
import _ from 'lodash'

export default {
  name: 'StyleGuideSettings',
  components: {
    MarkdownEditor
  },
  props: {
    contentplanId: {
      type: [Number, String],
      required: false
    },
    workspaceId: {
      type: [Number, String],
      required: false
    }
  },
  data () {
    return {
      isLoading: {
        contentplan: false,
        saving: false
      },
      styleGuide: ''
    }
  },
  computed: {
    ...mapGetters([
      'activeContentplan'
    ]),
    localWorkspaceId () {
      return this.workspaceId || parseInt(this.$route.params.workspaceId)
    },
    localContentplanId () {
      return this.contentplanId || parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan?.name
    }
  },
  methods: {
    ...mapActions([
      'loadContentplan',
      'updateContentplan'
    ]),
    async loadData () {
      this.isLoading.contentplan = true
      this.$emit('loading', true)
      try {
        const contentplan = await this.loadContentplan({
          workspaceId: this.localWorkspaceId,
          contentplanId: this.localContentplanId
        })
        this.styleGuide = contentplan.style_guide || ''
      } catch (error) {
        console.error('Error loading contentplan:', error)
      } finally {
        this.isLoading.contentplan = false
        this.$emit('loading', false)
      }
    },
    async saveStyleGuide () {
      this.isLoading.saving = true

      try {
        await this.updateContentplan({
          contentplanId: this.localContentplanId,
          style_guide: this.styleGuide
        })
      } catch (error) {
        console.error('Error saving style guide:', error)
      } finally {
        this.isLoading.saving = false
      }
    },
    // Manual save that emits the completed event
    async saveAndContinue () {
      await this.saveStyleGuide()
    }
  },
  watch: {
    styleGuide: _.debounce(function () {
      this.saveStyleGuide()
    }, 2000),
    contentplanId () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
    document.title = 'Style Guide | ContentGecko'
  }
}

import { mapActions } from 'vuex'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'
import keywordExclusionRegex from '@/utils/keywordExclusionRegex'

export default {
  name: 'FreeClusterer',
  data () {
    return {
      isLoading: {
        clusterer: false,
        user: false,
        email: false
      },

      keywordLimit: 200,
      keywords: null,
      keywordRules: [
        value => !value || value?.split(/[\r\n\t]+/)?.length <= this.keywordLimit || 'Too many keywords',
        value => !value || value?.split(/[\r\n\t]+/)?.length > 1 || 'Enter at least 2 keywords'
      ],
      domain: null,
      domainRules: [
        // value can be empty
        value => !value || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,}\/?$/.test(value) || 'Invalid domain'
      ],
      email: null,
      emailSent: false,
      emailSaved: false,
      emailRules: [
        value => !value || /.+@.+\..+/.test(value) || 'Invalid email'
      ],

      form: null,
      countries,
      country: 'USA',
      languages,
      language: 'en',
      serpOverlap: 2,

      crawlCompletion: 0,
      crawlTimeLeft: 0,

      interval: null,
      contentplanId: null,
      workspaceId: null,
      step: 0,
      csvUrl: null
    }
  },
  computed: {
    keywordsHint () {
      const keywordsArray = this.keywords?.split(/[\r\n\t]+/)?.filter(k => k)
      if (!keywordsArray?.length) return 'Enter a list of keywords, one per line'
      if (/,+/u.test(this.keywords)) return 'Enter one keyword per line. Don\'t use commas to seperate keywords'
      if (keywordsArray.some(k => new RegExp(keywordExclusionRegex.regex).test(k))) return 'Keywords with punctuation or special symbols will be ignored'
      return ''
    },
    signUpURI () {
      if (!this.contentplanId) return ''
      return `/auth/in?contentplanId=${this.contentplanId}`
    },
    isRusLocation () {
      return this.country === 'RUS' || this.country === 'BLR'
    },
    isRusDomain () {
      if (!this.form) return false
      return /(ru|by)\/?$/u.test(this.domain)
    },
    hasCleanKeywords () {
      const keywordsArray = this.keywords?.split(/[\r\n\t]+/)?.filter(k => k)
      if (!keywordsArray?.length) return true
      const keywordsCleaned = keywordsArray?.map(k => k.trim().toLowerCase()).filter(k => !!k).filter(k => {
        return !new RegExp(keywordExclusionRegex.regex).test(k)
      })

      return keywordsCleaned && keywordsCleaned.length > 0
    },
    errorMessage () {
      if (this.isRusLocation) return 'We don\'t support Russia or Belarus.'
      if (!this.hasCleanKeywords) return 'Please enter keywords without punctuation or special symbols'
      if (this.isRusDomain) return 'We don\'t support Russian or Belarussian domains.'
      return null
    }
  },
  watch: {
  },
  methods: {
    ...mapActions([
      'startClusterer',
      'getClustererStatus',
      'postUser',
      'clustererSendEmail'
    ]),
    countKeywords () {
      if (!this.keywords?.length) return 0
      // count keywords one per line
      return this.keywords?.split(/[\r\n\t]+/)?.filter(k => k).length || 0
    },
    async runClusterer () {
      try {
        if (!this.keywords?.length) return
        this.isLoading.clusterer = true

        // Turn this.keywords string into array
        const keywords = this.keywords.split(/[\r\n\t]+/)?.filter(k => k?.length)

        const result = await this.startClusterer({
          keywords,
          country: this.country,
          language: this.language,
          serpOverlap: this.serpOverlap,
          domain: this.domain,
          type: 'saas-cluster'
        })

        if (!result) {
          this.isLoading.clusterer = false
          console.log('No contentplan_id returned from startClusterer')
          return
        }
        this.contentplanId = result.contentplanId
        this.workspaceId = result.workspaceId

        this.step = 1

        // Check status every 5 seconds
        this.interval = setInterval(async () => {
          this.clustererStatus()
        }, 5000)
      } catch (err) {
        console.log(err)
        this.isLoading.clusterer = false
      }
    },
    async clustererStatus () {
      const contentplan = await this.getClustererStatus({
        contentplanId: this.contentplanId
      })

      this.crawlProgress(contentplan)
      await this.stepCounter(contentplan)

      if (this.step === 4) {
        this.csvUrl = contentplan.csv_url
        clearInterval(this.interval)
        this.isLoading.clusterer = false
      }
    },
    async stepCounter (contentplan) {
      if (!contentplan) return

      if (contentplan.import_started && !contentplan.crawl_started) {
        this.step = 1
      }
      if (contentplan.import_ended && contentplan.crawl_started && !contentplan.grouping_started) {
        this.step = 2
      }
      if (contentplan.crawl_ended && contentplan.grouping_started && !contentplan.grouping_ended) {
        this.step = 3
      }
      if (contentplan.grouping_ended && contentplan.csv_url) {
        this.step = 4
      }
    },
    crawlProgress (contentplan) {
      const totalKeywords = this.countKeywords()
      const keywordsToCrawl = contentplan.keywords_to_crawl || 0
      const keywordsCrawled = totalKeywords - keywordsToCrawl
      this.crawlCompletion = Math.round(keywordsCrawled * 100 / totalKeywords) || 0
      const avgTime = 10 // keywords per second
      const minutes = Math.round(keywordsToCrawl / avgTime / 60)
      this.crawlTimeLeft = minutes <= 1 ? 'less than a minute left' : `~${minutes} minutes left`
    },
    reset () {
      this.keywords = null
      this.country = 'USA'
      this.language = 'en'
      this.step = null
      this.csvUrl = null
      this.contentplanId = null
      this.workspaceId = null
      this.emailSent = false
      this.emailSaved = false
    },
    async addNewUser () {
      this.isLoading.user = true

      let userId
      try {
        userId = await this.postUser({
          contentplanId: this.contentplanId,
          email: this.email
        })
      } catch (err) {
        console.log(err)
      }

      this.emailSaved = true
      this.isLoading.user = false

      return userId
    },
    async addNewUserAndSendEmail () {
      this.isLoading.email = true

      try {
        const userId = await this.addNewUser()
        // console.log('userId', userId)
        await this.clustererSendEmail({
          userId,
          contentplanId: this.contentplanId
        })
        // console.log('response', response)
      } catch (err) {
        console.log(err)
      }

      this.emailSent = true
      this.isLoading.email = false
    }
  },
  async mounted () {
  }
}

import { mapGetters, mapMutations, mapActions } from 'vuex'

import Sidebar from '@/components/Sidebar/Sidebar.vue'
import ProgressTracker from '@/components/ProgressTracker/ProgressTracker.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    ProgressTracker
  },
  data () {
    return {
      isLoading: {
        workspaces: false
      },
      sideBarModel: true,
      status: null,
      statusRecheckInterval: null
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    // Watch for user login/logout to initialize/close websocket
    user: {
      immediate: true,
      handler (newUser, oldUser) {
        if (newUser && !oldUser) {
          // User logged in - initialize websocket
          this.initWebsocket()
        } else if (!newUser && oldUser) {
          // User logged out - close websocket
          this.closeWebsocket()
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'showSidebar',
      'workspaces',
      'activeWorkspace',
      'contentplans',
      'activeContentplan',
      'snackbar',
      'websocketConnected',
      'websocketMessages',
      'progressTrackers'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      if (this.activeContentplan) {
        return this.activeContentplan.name
      }
    },
    hideNav () {
      return this.$route.name === 'ClustererFree' || this.$route.name === 'RoiCalculator' || this.$route.name === 'WriterFree'
    },
    trialCountdown () {
      if (!this.activeWorkspace || !this.activeWorkspace.free_trial) return
      const now = new Date()
      let endDate = new Date()

      if (this.activeWorkspace.trial_extended_at) {
        const trialExtensionDays = 3
        const startDate = new Date(this.activeWorkspace.trial_extended_at)
        endDate = new Date(startDate.setDate(startDate.getDate() + trialExtensionDays))
      } else {
        const trialDays = 14
        const startDate = new Date(this.activeWorkspace.created_at)
        endDate = new Date(startDate.setDate(startDate.getDate() + trialDays))
      }

      if (now < endDate) {
        return Math.floor((endDate - now) / (1000 * 60 * 60 * 24))
      }

      return 0
    },
    showBanner () {
      return this.activeWorkspace && this.activeWorkspace.subscription_active === 0 && this.activeWorkspace.type !== 'saas'
    }
  },
  methods: {
    ...mapActions([
      'loadWorkspaces',
      'loadContentplans',
      'loadContentplan',
      'initWebsocket',
      'sendWebsocketMessage',
      'closeWebsocket'
    ]),
    ...mapMutations([
      'updateActiveWorkspace',
      'updateActiveContentplan',
      'setSnackbar',
      'setWebsocketConnected',
      'setSidebar',
      'addProgressTracker',
      'updateProgressTracker',
      'removeProgressTracker',
      'clearProgressTrackers'
    ]),
    async loadData () {
      // In these views don't load anything
      if (this.$route.name === 'ClustererFree') return
      if (this.$route.name === 'WriterFree') return
      if (this.$route.name === 'LoginManaged') return
      if (this.$route.name === 'sharedArticle' || this.$route.name === 'sharedArticleLegacy' || this.$route.name === 'DirectorShared') return
      if (this.$route.name === 'Login' || this.$route.name === 'Auth') return
      if (this.$route.name === 'CreateContentplan' || this.$route.name === 'CreateContentplanStep2') return
      if (this.$route.name === 'RoiCalculator') return
      if (this.$route.name === 'KeywordResearch') return
      if (this.$route.name === 'StripeLander') return
      if (this.$route.name === 'NotionIntegration') return
      this.isLoading.workspaces = true

      // If workspaces are not loaded, load them
      if (!this.workspaces) {
        console.log('Loading workspaces')
        try {
          await this.loadWorkspaces()
        } catch (e) {
          console.log('Error loading workspaces', e)
        }
      }

      // If no workspaces are returned, then user is logged out. Redirect to login page
      if (!this.workspaces?.length) {
        console.log('No workspaces, redirecting to login')
        this.$router.push('/auth/out')
        return
      }

      // If no workspaceId is in the route or if workspaceId is in the route but doesn't match any available workspace, redirect to first workspace
      if (!this.workspaceId || !this.workspaces.find(w => w.id === this.workspaceId)) {
        console.log('No workspaceId in route, redirecting to first workspace')
        // Prefer managed workspace if available
        if (this.workspaces.find(w => w.type === 'managed')) {
          this.$router.push({
            name: 'Workspace',
            params: {
              workspaceId: this.workspaces.find(w => w.type === 'managed').id
            }
          })
        } else {
          this.$router.push({
            name: 'Workspace',
            params: {
              workspaceId: this.workspaces[0].id
            }
          })
        }
        return
      }

      // If workspaceId is in the route, but no active workspace is set, set active workspace and get contentplans
      if (this.workspaceId && !this.activeWorkspace) {
        console.log('WorkspaceId in route, setting active workspace and getting contentplans')
        try {
          const workspace = this.workspaces.find(w => w.id === this.workspaceId)
          await this.updateActiveWorkspace(workspace)
          if (this.activeWorkspace?.type === 'saas') {
            if (!['ClustererPaid', 'WriterPaid', 'ResearchPaid'].includes(this.$route.name)) {
              this.$router.push({
                name: 'ClustererPaid',
                params: {
                  workspaceId: this.workspaceId
                }
              })
            }
            return
          } else {
            await this.loadContentplans({
              workspaceId: this.workspaceId
            })
          }
        } catch (e) {
          console.log('Error loading contentplans', e)
        }
      }

      // If its a saas workspace, stop here
      if (this.activeWorkspace?.type === 'saas') {
        this.isLoading.workspaces = false
        return
      }

      // If workspace subscription has expired and route is not workspace view, redirect to workspace view
      if (this.activeWorkspace?.free_trial && !this.trialCountdown) {
        console.log('Workspace has expired, redirecting to workspace view')
        if (this.$route.name !== 'Workspace') {
          this.$router.push({
            name: 'Workspace',
            params: {
              workspaceId: this.workspaceId
            }
          })
        }
        return
      }

      // If no contentplans are returned, redirect to create contentplan page
      if (!this.contentplans?.length) {
        console.log('No contentplans, redirecting to create contentplan')
        this.$router.push({
          name: 'CreateContentplan',
          params: {
            workspaceId: this.workspaceId
          }
        })
        return
      }

      // If only one contentplan is returned AND it has no domain, redirect to create contentplan page (2nd step)
      if (this.contentplans.length === 1 && !this.contentplans[0].domain) {
        console.log('Only one contentplan with no domain, redirecting to create contentplan step 2')
        this.$router.push({
          name: 'CreateContentplanStep2',
          params: {
            workspaceId: this.workspaceId,
            contentplanId: this.contentplans[0].id
          }
        })
        return
      }

      // If no contentplan in the route, redirect to the first contentplan.
      if (!this.contentplanId && this.contentplans?.length) {
        console.log('No contentplanId in route, redirecting to first contentplan')
        this.$router.push({
          name: 'Home',
          params: {
            workspaceId: this.workspaceId,
            contentplanId: this.contentplans[0].id
          }
        })
        return
      }

      // If contentplan in route, then set it as active contentplan
      if (this.contentplanId) {
        console.log('ContentplanId in route, setting active contentplan')
        const contentplan = this.contentplans.find(c => c.id === this.contentplanId)
        this.updateActiveContentplan(contentplan)
      }

      this.isLoading.workspaces = false
    },
    openSidebar () {
      this.sideBarModel = true
      this.setSidebar(true)
    },
    handleWebsocketMessage (message) {
      if (message.type !== 'tracker') return
      const { contentplanId, clusterId, stepName, status } = message.messages[0]
      this.updateProgressTracker({
        contentplanId,
        clusterId,
        stepName,
        status
      })
    },
    handleAllStepsCompleted (data) {
      // Emit a global event that can be listened to by any component
      this.$root.$emit('progress-tracker-completed', data)
    }
  },
  async mounted () {
    await this.loadData()

    // Initialize websocket if user is already logged in
    if (this.user) {
      this.initWebsocket()
    }
  },
  created () {
    // Subscribe to websocket messages for tracking article creation progress
    this.$store.subscribeAction({
      after: (action) => {
        if (action.type === 'handleWebsocketMessage' && action.payload) {
          this.handleWebsocketMessage(action.payload)
        }
      }
    })
  },
  beforeDestroy () {
    // Clean up websocket connection when component is destroyed
    this.closeWebsocket()

    // Clear any existing intervals
    if (this.statusRecheckInterval) {
      clearInterval(this.statusRecheckInterval)
    }
  }
}

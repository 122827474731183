import { mapGetters, mapActions } from 'vuex'

import tooltips from '@/utils/tooltips'
import parseData from '@/utils/parseData'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  name: 'Hubs',
  props: {
    contentplanId: {
      type: [Number, String],
      required: false
    },
    workspaceId: {
      type: [Number, String],
      required: false
    },
    autoGenerateOnMount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: {
        topics: false,
        saveDescription: false,
        saveName: false,
        delete: false,
        autofillHubs: false
      },
      selectedTopicId: null,
      isSavingDescription: false,
      saveTimeoutDescription: null,
      saveDelay: 1000,
      isCreatingTopic: false,
      editingTopicName: false,
      hubNameEdit: '',
      showDeleteDialog: false,
      topicToDelete: null
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'activeContentplan'
    ]),
    localWorkspaceId () {
      return this.workspaceId || parseInt(this.$route.params.workspaceId)
    },
    localContentplanId () {
      return this.contentplanId || parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan?.name
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    contentplanId () {
      this.loadData()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadTopics',
      'updateTopics',
      'addTopic',
      'setSnackbar',
      'createHubsBulk'
    ]),
    // RENDER DATA
    async loadData () {
      document.title = 'Hubs | ContentGecko'
      try {
        this.isLoading.topics = true
        this.$emit('loading', true)

        await Promise.all([
          this.loadTopics({
            contentplanId: this.localContentplanId
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.topics = false
        this.$emit('loading', false)
      }
    },
    topicName (topicId) {
      if (!this.topics) return

      const topic = this.topics.find(t => t.id === topicId)
      return topic?.name
    },
    async handleCreateHubClick () {
      this.isLoading.createHub = true

      try {
        const newTopicId = await this.addNewTopic()
        console.log('new topic id', newTopicId)
      } catch (error) {
        console.log(error)
      }

      this.isLoading.createHub = false
    },
    async handleSaveHubClick (topicId) {
      this.isLoading.saveHub = true

      try {
        await this.updateTopicTags(topicId)
      } catch (error) {
        console.log(error)
      }

      this.isLoading.saveHub = false
    },
    openCreateHubDialog () {
      this.createNewTopic()
    },
    async createNewTopic () {
      if (this.isCreatingTopic) return
      this.isCreatingTopic = true
      try {
        // Add new topic with default name
        const topicId = await this.addTopic({
          contentplanId: this.localContentplanId,
          name: 'New Hub',
          description: ''
        })
        // Refresh the topics list
        await this.loadData()
        // Start editing the name of the new hub
        if (this.topics) {
          const newTopic = this.topics.find(t => t.id === topicId)
          if (newTopic) {
            this.startTopicNameEdit(newTopic)
          }
        }
      } catch (error) {
        console.error('Error creating new hub:', error)
        this.setSnackbar({
          open: true,
          text: 'Failed to create new hub',
          type: 'error'
        })
      } finally {
        this.isCreatingTopic = false
      }
    },
    topicRankChange (topicId) {
      if (!this.topics) return

      const topic = this.topics.find(t => t.id === topicId)
      return parseData((topic.google_position_last - topic.google_position_prev) / topic.google_position_last * 100)
    },
    // Description editing methods
    topicDescription (topicId) {
      if (!this.topics) return ''
      const topic = this.topics.find(t => t.id === topicId)
      return topic?.description || ''
    },
    updateTopicDescription (topicId, newContent) {
      if (!this.topics) return
      // Set the current editing topic
      this.selectedTopicId = topicId

      // Find and update the topic in the local state
      const topic = this.topics.find(t => t.id === topicId)
      if (topic) {
        topic.description = newContent
      }
      // Auto-save with debounce
      if (!this.isSavingDescription) {
        clearTimeout(this.saveTimeoutDescription)
        this.saveTimeoutDescription = setTimeout(() => {
          this.saveDescription(topicId, newContent)
        }, this.saveDelay)
      }
    },
    async saveDescription (topicId, description) {
      this.isSavingDescription = true
      this.selectedTopicId = topicId
      try {
        await this.updateTopics({
          contentplanId: this.localContentplanId,
          topicIds: [topicId],
          description,
          action: 'updateDescription'
        })
      } catch (error) {
        console.error('Error saving description:', error)
        this.setSnackbar({
          open: true,
          text: 'Failed to save hub description',
          type: 'error'
        })
      } finally {
        this.isSavingDescription = false
        this.selectedTopicId = null
      }
    },
    // In-place editing for hub name
    startTopicNameEdit (topic) {
      this.editingTopicName = true
      this.selectedTopicId = topic.id
      this.hubNameEdit = topic.name
    },
    cancelTopicNameEdit () {
      this.editingTopicName = false
      this.selectedTopicId = null
      this.hubNameEdit = ''
    },
    async saveTopicName (topicId) {
      if (!this.hubNameEdit.trim()) {
        this.hubNameEdit = 'New Hub'
      }
      this.isLoading.saveName = topicId
      try {
        await this.updateTopics({
          contentplanId: this.localContentplanId,
          topicIds: [topicId],
          name: this.hubNameEdit.trim(),
          action: 'rename'
        })
        // Update name in local state
        if (this.topics) {
          const topic = this.topics.find(t => t.id === topicId)
          if (topic) {
            topic.name = this.hubNameEdit.trim()
          }
        }
      } catch (error) {
        console.error('Error saving hub name:', error)
        this.setSnackbar({
          open: true,
          text: 'Failed to save hub name',
          type: 'error'
        })
      } finally {
        this.editingTopicName = false
        this.selectedTopicId = null
        this.hubNameEdit = ''
        this.isLoading.saveName = false
      }
    },
    // Topic deletion methods
    confirmDeleteTopic (topic) {
      this.topicToDelete = topic
      this.showDeleteDialog = true
    },

    async deleteTopic () {
      if (!this.topicToDelete) return
      const topicId = this.topicToDelete.id
      this.isLoading.delete = topicId
      try {
        await this.updateTopics({
          contentplanId: this.localContentplanId,
          topicIds: [topicId],
          action: 'delete'
        })
        // Refresh the data to update the UI
        await this.loadData()
      } catch (error) {
        console.error('Error deleting hub:', error)
        this.setSnackbar({
          open: true,
          text: 'Failed to delete hub',
          type: 'error'
        })
      } finally {
        this.isLoading.delete = false
        this.showDeleteDialog = false
        this.topicToDelete = null
      }
    },
    async autofillHubs () {
      if (this.isLoading.autofillHubs) return
      this.isLoading.autofillHubs = true
      this.$emit('loading', true)

      try {
        // Call the create-hubs lambda via the store action
        await this.createHubsBulk({
          contentplanId: this.localContentplanId,
          createAndMap: false
        })

        // Refresh the topics list
        await this.loadData()
      } catch (error) {
        console.error('Error autofilling hubs:', error)
        this.setSnackbar({
          open: true,
          text: 'Failed to autofill hubs',
          type: 'error'
        })
      } finally {
        this.isLoading.autofillHubs = false
        this.$emit('loading', false)
      }
    }
  },
  async mounted () {
    await this.loadData()
    // Auto-trigger hubs generation if coming from CreateContentplan flow
    if (this.autoGenerateOnMount && (!this.topics || this.topics.length === 0)) {
      this.autofillHubs()
    }
  },
  components: {
    MarkdownEditor
  }
}

import { mapGetters, mapActions, mapMutations } from 'vuex'

import AddKeywords from '@/components/AddKeywords/AddKeywords.vue'

export default {
  name: 'Keywords',
  components: {
    AddKeywords
  },
  data () {
    return {
      isLoading: {
        exclude: false,
        keywords: false,
        resetKeyword: false,
        clusterer: false
      },

      dialogAddKeywords: false,
      dialogKey: 0,

      showClusterConfirmationDialog: false,
      confirmationData: null,

      selectedKeywords: [],
      selectAll: false,
      filterUrl: '',
      filterKeyword: '',

      sourceFilter: 'all',
      selectedStatuses: 'all',
      statuses: [
        { text: 'All', value: 'all', count: 'totalCount' },
        { text: 'Active', value: 'in_plan', count: 'inPlanCount' },
        { text: 'Inactive', value: 'not_in_plan', count: 'notInPlanCount' },
        { text: 'Excluded', value: 'excluded', count: 'excludedCount' }
      ],
      sources: [
        { text: 'All sources', value: 'all' },
        { text: 'Search Console', value: 'google_search_console' },
        { text: 'Uploaded', value: 'upload' },
        { text: 'Related', value: 'related' },
        { text: 'Auto-expand', value: 'auto-expand' }
      ],
      tableMainKwListOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['estimated_search_volume'],
        sortDesc: [true]
      },
      tableMainKwList: {
        headers: [
          { text: 'Keyword', value: 'keyword' },
          { text: 'Search volume', value: 'estimated_search_volume' },
          { text: 'Impressions', value: 'google_impressions_last' },
          { text: 'Clicks', value: 'google_clicks_last' },
          { text: 'CTR', value: 'google_ctr_last' },
          { text: 'Rank', value: 'google_position_last' },
          { text: 'Cluster title', value: 'title', sortable: false },
          { text: 'Source', value: 'source', sortable: false },
          { value: 'actions' }
        ]
      },
      gscFilters: {
        estimated_search_volume: {
          min: null,
          max: null
        },
        google_impressions_last: {
          min: null,
          max: null
        },
        google_clicks_last: {
          min: null,
          max: null
        },
        google_ctr_last: {
          min: null,
          max: null
        },
        google_position_last: {
          min: null,
          max: null
        }
      }
    }
  },
  async mounted () {
    await this.loadData()
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async tableMainKwListOptions () {
      if (!this.isLoading.keywords) {
        await this.fillKeywordsTable()
      }
    },
    async sourceFilter () {
      this.fillKeywordsTable()
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'workspaces',
      'activeWorkspace',
      'activeContentplan',
      'keywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    showResetAction () {
      if (this.selectedKeywords?.length < 1) return
      // Show reset action if any of the selected keywords is in a cluster or is excluded
      if (this.selectedKeywords.some(kw => kw.title || kw.excluded)) return true
    },
    showExcludeAction () {
      if (this.selectedKeywords?.length < 1) return
      // Show exclude action if any of the selected keywords is not excluded
      if (this.selectedKeywords.some(kw => !kw.excluded)) return true
    },
    isAnyKeywordSelected () {
      return this.selectedKeywords?.length > 0
    }
  },
  methods: {
    ...mapActions([
      'loadContentplanKeywords',
      'updateKeywords',
      'sendWebsocketMessage'
    ]),
    ...mapMutations([
      'addProgressTracker'
    ]),
    itemClassPrepend (item) {
      return item.excluded ? 'excluded' : ''
    },
    async loadData () {
      if (this.isLoading.keywords) return
      this.isLoading.keywords = true
      document.title = 'Keywords | ContentGecko'

      try {
        this.fillKeywordsTable()
      } catch (e) {
        console.log(e)
      }
    },
    async fillKeywordsTable () {
      this.isLoading.keywords = true

      await this.loadContentplanKeywords({
        workspaceId: this.workspaceId,
        contentplanId: this.contentplanId,
        q: this.filterKeyword || '.*',
        limit: this.tableMainKwListOptions.itemsPerPage || 100,
        page: this.tableMainKwListOptions.page || 1,
        url: this.filterUrl,
        status: this.selectedStatuses === 'all' ? ['in_plan', 'not_in_plan', 'excluded'] : this.selectedStatuses,
        source: this.sourceFilter === 'all' ? ['google_search_console', 'upload', 'related'] : this.sourceFilter,
        sortColumn: this.tableMainKwListOptions.sortBy[0] || 'estimated_search_volume',
        sortDirection: this.tableMainKwListOptions.sortDesc[0] ? 'desc' : 'asc'
      })

      this.isLoading.keywords = false
    },
    async excludeKeywords (keywordId) {
      if (!this.selectedKeywords?.length && !keywordId) return

      this.isLoading.exclude = true
      const selectedKeywordIds = this.selectedKeywords.map(el => el.id)

      const keywordIds = keywordId ? [keywordId] : selectedKeywordIds

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds,
          action: 'exclude'
        })
        this.selectedKeywords = []
      } finally {
        await this.fillKeywordsTable()
        this.isLoading.exclude = false
      }
    },
    async resetKeywords (keywordId) {
      if (!this.selectedKeywords?.length && !keywordId) return

      this.isLoading.resetKeyword = true
      const selectedKeywordIds = this.selectedKeywords.map(el => el.id)

      const keywordIds = keywordId ? [keywordId] : selectedKeywordIds

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds,
          action: 'reset'
        })
        this.selectedKeywords = []
      } finally {
        await this.fillKeywordsTable()
        this.isLoading.resetKeyword = false
      }
    },
    isStatusSelected (status) {
      return this.selectedStatuses.includes(status)
    },
    async handleStatusClick (status) {
      if (status === 'all') {
        this.selectedStatuses = 'all'
      } else {
        this.selectedStatuses = [status]
      }

      await this.fillKeywordsTable()
    },
    async handleDialogClick () {
      this.dialogAddKeywords = !this.dialogAddKeywords
      if (this.dialogAddKeywords) this.dialogKey += 1
      if (!this.dialogAddKeywords) await this.fillKeywordsTable()
    },
    async runClusterer () {
      const activeKeywords = this.keywords?.keywordCounts?.inPlanCount ?? 0
      const inactiveKeywords = this.keywords?.keywordCounts?.notInPlanCount ?? 0
      const planLimit = this.activeContentplan?.keyword_limit ?? 0
      const keywordsToActivate = Math.max(0, Math.min(inactiveKeywords, planLimit - activeKeywords))

      this.confirmationData = {
        activeKeywords,
        inactiveKeywords,
        planLimit,
        keywordsToActivate
      }

      this.showClusterConfirmationDialog = true
    },
    async executeClustering () {
      this.showClusterConfirmationDialog = false
      this.isLoading.clusterer = true
      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          action: 'queue'
        })

        this.addProgressTracker({
          contentplanId: this.contentplanId,
          name: 'Adding keywords to clusters',
          stepsObject: {
            Crawling: 'inProgress',
            Clustering: 'pending',
            Labeling: 'pending',
            'Page matching': 'pending'
          }
        })

        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            runGrouping: true,
            userType: 'managed'
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading.clusterer = false
      }
    }
  }
}

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { funnel, types, intents } from '@/utils/clusterVariables.js'

export default {
  name: 'CreateArticle',
  components: {},
  props: {
    workspaceId: {
      type: Number,
      required: false
    },
    contentplanId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      isLoading: {
        keywordIdeas: false,
        creatingArticle: false,
        writingArticle: false
      },
      prompt: '',
      displayWritePopup: false,
      selectedArticle: null,
      selectedFunnel: null,
      selectedIntent: null,
      selectedType: null,
      selectedTopic: null,
      funnel,
      types,
      intents,
      keywordIdeas: [],
      loadingMessage: '',
      loadingStep: '',
      currentArticleId: null,
      articleCompleted: false
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    isLoading: {
      handler (val) {
        const isAnyLoading = Object.values(val).some(loadState => loadState)
        this.$emit('loading', isAnyLoading)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspace',
      'activeContentplan',
      'topics',
      'websocketConnected'
    ]),
    computedWorkspaceId () {
      return this.workspaceId || parseInt(this.$route.params.workspaceId)
    },
    computedContentplanId () {
      return this.contentplanId || parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    topicSelection () {
      const topics = this.topics?.map(topic => ({
        text: topic.name,
        value: topic.topic_id
      })) ?? []
      return topics
    }
  },
  methods: {
    ...mapActions([
      'loadTopics',
      'addArticle',
      'postHubExpand',
      'sendWebsocketMessage',
      'updateArticles'
    ]),
    ...mapMutations([
      'addProgressTracker'
    ]),
    selectKeyword (keyword, topicId) {
      this.prompt = keyword
      this.createAndOpenArticle(keyword, topicId)
    },
    async createAndOpenArticle (title, topicId = null) {
      this.isLoading.creatingArticle = true
      this.loadingStep = 'Creating article...'

      try {
        const newArticle = await this.addArticle({
          contentplanId: this.computedContentplanId,
          name: title,
          topicId
        })
        this.loadingStep = 'Setting up article details...'
        this.openWritePopup(newArticle)
      } catch (error) {
        console.log(error)
        this.loadingStep = 'Error creating article'
      } finally {
        this.isLoading.creatingArticle = false
      }
    },
    async loadData () {
      document.title = 'New article | ContentGecko'
      this.isLoading.keywordIdeas = true
      try {
        const result = await Promise.all([
          this.loadTopics({
            contentplanId: this.computedContentplanId
          }),
          this.postHubExpand({
            contentplanId: this.computedContentplanId,
            runRelatedKeywords: false,
            saveKeywords: false
          })
        ])
        this.keywordIdeas = result[1]
      } catch (error) {
        console.error('Error loading keyword ideas:', error)
      } finally {
        this.isLoading.keywordIdeas = false
      }
    },
    topicIdToName (topicId) {
      const topic = this.topics.find(t => t.topic_id === topicId)
      return topic ? topic.name : null
    },
    openWritePopup (item) {
      this.selectedTopic = item.topic_id
      this.selectedFunnel = item.funnel
      this.selectedIntent = item.intent
      this.selectedType = item.type
      this.displayWritePopup = true
      this.selectedArticle = item
    },
    closeWritePopup () {
      this.prompt = ''
      this.selectedArticle = null
      this.selectedTopic = null
      this.selectedFunnel = null
      this.selectedIntent = null
      this.selectedType = null
      this.displayWritePopup = false
    },
    async changeTopic (articleId, topicId) {
      if (!articleId || !topicId) return
      this.isLoading.changeTopic = true

      try {
        await this.updateArticles({
          workspaceId: this.computedWorkspaceId,
          contentplanId: this.computedContentplanId,
          action: 'updateTopic',
          articleIds: [articleId],
          topicId
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading.changeTopic = false
      }
    },
    async changeFunnel (articleId, funnel) {
      if (!articleId || !funnel) return
      this.isLoading.changeFunnel = true

      try {
        await this.updateArticles({
          workspaceId: this.computedWorkspaceId,
          contentplanId: this.computedContentplanId,
          action: 'updateFunnel',
          articleIds: articleId,
          funnel
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading.changeFunnel = false
      }
    },
    async changeIntent (articleId, intent) {
      if (!articleId || !intent) return
      this.isLoading.changeIntent = true

      try {
        await this.updateArticles({
          workspaceId: this.computedWorkspaceId,
          contentplanId: this.computedContentplanId,
          action: 'updateIntent',
          articleIds: articleId,
          intent
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading.changeIntent = false
      }
    },
    async changeType (articleId, type) {
      if (!articleId || !type) return
      this.isLoading.changeType = true

      try {
        await this.updateArticles({
          workspaceId: this.computedWorkspaceId,
          contentplanId: this.computedContentplanId,
          action: 'updateType',
          articleIds: articleId,
          type
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading.changeType = false
      }
    },
    handleWebsocketMessage (message) {
      if (message.type !== 'tracker') return
      const { clusterId, stepName } = message.messages[0]
      this.loadingStep = 'Creating SEO brief..'

      // Only handle messages for the current article being generated
      if (clusterId !== this.currentArticleId) return

      // Update loading step based on the current step
      switch (stepName) {
        case 'Briefing':
          this.loadingStep = 'Researching the topic...'
          break
        case 'Researching':
          this.loadingStep = 'Writing first draft...'
          break
        case 'Drafting':
          this.loadingStep = 'Polishing the content...'
          break
      }

      // If Editing is completed, show the completion message
      if (stepName === 'Editing') {
        this.articleCompleted = true
        this.isLoading.writingArticle = false
      }
    },
    async writeArticle (articleId, articleTitle) {
      this.displayWritePopup = false
      this.isLoading.writingArticle = true
      this.loadingStep = 'Starting article writing process...'
      this.currentArticleId = articleId

      try {
        await Promise.all([
          this.updateArticles({
            workspaceId: this.computedWorkspaceId,
            contentplanId: this.computedContentplanId,
            action: 'addToMap',
            articleIds: [articleId]
          }),
          this.sendWebsocketMessage({
            action: 'runOrchestrator',
            message: {
              contentplanId: this.computedContentplanId,
              clusterIds: [articleId],
              runUpdating: false,
              runGrouping: false,
              runMapping: false,
              runWriting: true,
              selectBy: 'cluster',
              publishTo: this.activeContentplan.main_cms_integration || 'autoblog',
              userType: 'managed'
            }
          })
        ])
      } catch (error) {
        console.log(error)
        this.loadingStep = 'Error starting article writing process'
        this.isLoading.writingArticle = false
      }
    }
  },
  async mounted () {
    await this.loadData()

    // Subscribe to websocket messages
    this.$store.subscribeAction({
      after: (action) => {
        if (action.type === 'handleWebsocketMessage' && action.payload) {
          this.handleWebsocketMessage(action.payload)
        }
      }
    })
  },
  beforeDestroy () {
    // Clean up any ongoing processes
    if (this.currentArticleId) {
      this.isLoading.creatingArticle = false
      this.isLoading.writingArticle = false
      this.currentArticleId = null
    }
  }
}

import { mapActions, mapGetters } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  data () {
    return {
      isLoading: {
        agent: false,
        writeAll: false
      },
      agents: {
        brief: { name: 'SEO specialist', color: 'blue' },
        research: { name: 'Researcher', color: 'blue' },
        draft: { name: 'Writer', color: 'blue' },
        editor: { name: 'Editor', color: 'blue' }
      },
      userMessage: '',
      messages: []
    }
  },
  watch: {
    messages: {
      handler (newMessages) {
        this.$emit('messages-updated', newMessages)
      },
      deep: true
    }
  },
  mounted () {
    // We don't need to connect to websocket here anymore
    // The App.js component handles the connection
  },
  computed: {
    ...mapGetters([
      'websocketUrl',
      'websocketConnected' // Add this getter to check connection status
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    filteredMessages () {
      return this.messages?.filter(m => m.role !== 'data') || []
    }
  },
  methods: {
    ...mapActions([
      'sendWebsocketMessage' // Add this action
    ]),

    // Update the sendMessage method
    async sendMessage (body) {
      try {
        await this.sendWebsocketMessage(body)
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    },

    // Handle websocket messages
    handleWebsocketMessage (response) {
      if (!response?.messages) return

      // if response is data, store as new message
      if (response.messages[0]?.role === 'data') {
        this.$emit('data-message', response.messages[0])
        return
      }

      // otherwise append to last assistant message
      if (this.messages[this.messages.length - 1]?.loading) {
        // remove loading message and replace with empty assistant message
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: '' })
      }

      this.messages[this.messages.length - 1].content += response.messages[0]?.content
    },

    async copyToClipboard (text) {
      await navigator.clipboard.writeText(text)
    },
    async runAgent (agentType) {
      this.isLoading.agent = agentType
      // push user message
      this.messages.push({ role: 'user', content: `*Run ${agentType} agent*` })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'callAgent',
          message: {
            clusterId: this.articleId,
            agent: agentType
          }
        })
      } catch (err) {
        console.error(err)
        console.log('Error')
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }

      this.isLoading.agent = false
    },
    async sendChatMessage () {
      // console.log('sendChatMessage', this.userMessage)
      // push user message
      this.messages.push({ role: 'user', content: this.userMessage })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'sendMessage',
          message: {
            contentplanId: this.contentplanId,
            clusterId: this.articleId,
            pageId: this.pageId,
            messages: this.messages.filter(m => m.content !== '')
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    }
  },
  props: {
    pageId: {
      type: Number,
      default: null
    },
    showAgents: {
      type: Boolean,
      default: true
    },
    showSuggestions: {
      type: Boolean,
      default: true
    },
    systemMessage: {
      type: String,
      default: 'I am Gecko, AI co-pilot designed to help you produce the best page for this keyword cluster. I have access to all the data you need - keywords, your pages, competitor pages and SERP data. Ask me anything!'
    },
    showSystemMessage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MarkdownEditor
  },
  created () {
    this.$store.subscribeAction({
      after: (action) => {
        if (action.type === 'handleWebsocketMessage' && action.payload) {
          this.handleWebsocketMessage(action.payload)
        }
      }
    })
  },
  beforeDestroy () {
    // Nothing to clean up here as App.js manages the connection
  }
}

import { mapActions, mapGetters } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'
import _ from 'lodash'

export default {
  name: 'AboutSettings',
  components: {
    MarkdownEditor
  },
  props: {
    contentplanId: {
      type: [Number, String],
      required: false
    },
    workspaceId: {
      type: [Number, String],
      required: false
    },
    autoGenerateOnMount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: {
        contentplan: false,
        saving: false,
        autoInfo: false
      },
      aboutInfo: ''
    }
  },
  computed: {
    ...mapGetters([
      'activeContentplan'
    ]),
    localWorkspaceId () {
      return this.workspaceId || parseInt(this.$route.params.workspaceId)
    },
    localContentplanId () {
      return this.contentplanId || parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan?.name
    }
  },
  methods: {
    ...mapActions([
      'loadContentplan',
      'updateContentplan',
      'getAboutInfoAuto'
    ]),
    async loadData () {
      this.isLoading.contentplan = true
      this.$emit('loading', true)
      try {
        const contentplan = await this.loadContentplan({
          workspaceId: this.localWorkspaceId,
          contentplanId: this.localContentplanId
        })
        this.aboutInfo = contentplan.about || ''
      } catch (error) {
        console.error('Error loading contentplan:', error)
      } finally {
        this.isLoading.contentplan = false
        this.$emit('loading', false)
      }
    },
    async saveAboutInfo () {
      this.isLoading.saving = true

      try {
        await this.updateContentplan({
          contentplanId: this.localContentplanId,
          about: this.aboutInfo
        })
      } catch (error) {
        console.error('Error saving company information:', error)
      } finally {
        this.isLoading.saving = false
      }
    },
    // Manual save that emits the completed event
    async saveAndContinue () {
      await this.saveAboutInfo()
    },
    // Auto-generate company information
    async getAutoAboutInfo () {
      this.isLoading.autoInfo = true
      this.$emit('loading', true)
      try {
        const response = await this.getAboutInfoAuto({
          contentplanId: this.localContentplanId
        })
        this.aboutInfo = response.about
      } catch (error) {
        console.error('Error auto-generating about info:', error)
      } finally {
        this.isLoading.autoInfo = false
        this.$emit('loading', false)
      }
    }
  },
  watch: {
    aboutInfo: _.debounce(function () {
      this.saveAboutInfo()
    }, 2000),
    contentplanId () {
      this.loadData()
    }
  },
  async mounted () {
    await this.loadData()
    document.title = 'About Your Company | ContentGecko'
    // Auto-trigger generation if coming from CreateContentplan flow
    if (this.autoGenerateOnMount && !this.aboutInfo) {
      this.getAutoAboutInfo()
    }
  }
}

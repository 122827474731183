import { mapGetters, mapActions, mapMutations } from 'vuex'

import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'
import SnackbarAction from '@/components/SnackbarAction/SnackbarAction.vue'
import { statuses, funnel, types } from '@/utils/clusterVariables.js'

export default {
  name: 'ContentEditor',
  data () {
    return {
      isLoading: {
        articles: false,
        setStatus: null,
        upload: null,
        reject: null,
        savingFinalDraft: false,
        rewrite: false
      },
      saveTimeoutFinalDraft: null,
      saveDelay: 1000,

      tableHeaders: [
        { text: '', value: 'select', sortable: false },
        { text: 'Title', value: 'final' },
        { text: 'Slug', value: 'slug' },
        { text: 'Status', value: 'status' },
        { text: 'Last updated', value: 'last_updated' },
        { text: '', value: 'action' }
      ],
      tabs: [
        { text: 'Unpublished', value: 'unpublished' },
        { text: 'Published', value: 'published' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'All', value: 'all' }
      ],
      activeTab: 0,

      // Rewrite dialog
      showRewriteDialog: false,
      rewriteArticles: [],
      rewriteStatus: null,
      rewriteTableHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'status' }
      ],

      selectedArticle: null,
      selectedArticleContent: null,
      statuses,
      funnel,
      types,
      selectedArticles: [],
      showSnackbar: false
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async activeTab () {
      await this.loadData()
    },
    selectedArticle: {
      handler (newVal) {
        if (newVal) {
          window.addEventListener('keydown', this.handleEscKey)
        } else {
          window.removeEventListener('keydown', this.handleEscKey)
        }
      }
    },
    selectedArticleContent: {
      handler (newValue) {
        if (!this.isLoading.savingFinalDraft && newValue !== this.selectedArticle?.final) {
          console.log('Saving final draft...')
          this.isLoading.savingFinalDraft = true
          clearTimeout(this.saveTimeoutFinalDraft)
          this.saveTimeoutFinalDraft = setTimeout(() => {
            this.saveContent(this.selectedArticle?.article_id)
            this.isLoading.savingFinalDraft = false
          }, this.saveDelay)
        }
      },
      deep: true
    },
    selectedArticles: {
      handler (newVal) {
        console.log('Selected articles changed:', newVal.length)
        this.showSnackbar = newVal.length > 0
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    hasSelectedArticles () {
      return this.showSnackbar
    },
    snackbarActions () {
      return [
        {
          text: 'Upload',
          event: 'upload-selected',
          loading: this.isLoading.upload,
          disabled: false
        },
        {
          text: 'Rewrite',
          event: 'rewrite-selected',
          loading: this.isLoading.rewrite,
          disabled: false
        },
        {
          text: 'Reject',
          event: 'reject-selected',
          loading: this.isLoading.reject,
          disabled: false
        }
      ]
    },
    snackbarTitle () {
      if (!this.selectedArticles?.length) return ''
      return this.selectedArticles.length + ' article(s) selected'
    },
    statusOptions () {
      const statusOptions = [
        { text: 'No status - rewrite all steps', value: 'no status' },
        { text: 'Briefed - rewrite research, draft and final draft', value: 'briefed' },
        { text: 'Researched - rewrite draft and final draft', value: 'researched' },
        { text: 'Drafted - rewrite only final draft', value: 'drafted' }
      ]
      return statusOptions
    }
  },
  methods: {
    ...mapActions([
      'loadArticlesContent',
      'updateArticles',
      'updateProgressTracker',
      'sendWebsocketMessage'
    ]),
    ...mapMutations([
      'addProgressTracker'
    ]),
    async loadData () {
      this.isLoading.articles = true
      document.title = 'ContentEditor | ContentGecko'

      try {
        await Promise.all([
          this.loadArticlesContent({
            contentplanId: this.contentplanId,
            statusFilter: this.tabs[this.activeTab].value
          })
        ])
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.articles = false
    },
    editArticle (article) {
      this.selectedArticle = article // Set the selected article
      console.log('Selected article:', article)
      this.selectedArticleContent = article.final // Set the selected article content
    },
    copyArticleMarkdown (article) {
      if (!article) return

      const markdown = article.final
      // Add the markdown to the clipboard
      navigator.clipboard.writeText(markdown)
    },
    copySlug (article) {
      if (!article) return

      const slug = article.slug
      // Add the slug to the clipboard
      navigator.clipboard.writeText(slug)
    },
    updateFinalDraft (newContent) {
      if (this.selectedArticle) {
        this.selectedArticle.final = newContent // Update the final draft content
        this.selectedArticleContent = newContent // Update the selected article content
      }
    },
    async saveContent (articleId) {
      if (!articleId) return

      this.isLoading.saveContent = true
      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateFinalDraft',
        articleIds: [articleId],
        final: this.selectedArticleContent
      })

      this.isLoading.saveContent = false
    },
    async closeEditor () {
      this.selectedArticle = null // Close the editor by resetting the selected article
      this.selectedArticleContent = null // Reset the selected article content
      await this.loadData() // Reload
    },
    getStatusColor (status) {
      const statusObj = this.statuses.find(s => s.value === status)
      return statusObj ? statusObj.color : null
    },
    getFunnelColor (funnel) {
      const funnelObj = this.funnel.find(f => f.value === funnel)
      return funnelObj ? funnelObj.color : null
    },
    getTypeColor (type) {
      const typeObj = this.types.find(t => t.value === type)
      return typeObj ? typeObj.color : null
    },
    async setStatus (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setStatus = clusterId

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateStatus',
          articleIds: [clusterId],
          status: value
        })

        // close editor
        this.closeEditor()
      } finally {
        this.isLoading.setStatus = false
      }
    },
    async setFunnel (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setFunnel = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateFunnel',
          articleIds: [clusterId],
          funnel: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setFunnel = false
      }
    },
    async setType (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setType = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateType',
          articleIds: [clusterId],
          type: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setType = false
      }
    },
    async setPriority (clusterId, value) {
      if (!value) return
      if (!clusterId) return
      this.isLoading.reject = clusterId

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updatePriority',
          articleIds: [clusterId],
          priority: value
        })
        await this.loadData()
      } finally {
        this.isLoading.reject = null
      }
    },
    handleEscKey (event) {
      if (event.key === 'Escape') {
        this.closeEditor()
      }
    },
    async handleUploadCluster (clusterId) {
      if (!clusterId) return

      this.isLoading.upload = clusterId

      try {
        // Run orchestrator
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            clusterIds: [clusterId],
            runUpdating: false,
            runGrouping: false,
            runMapping: false,
            runWriting: true,
            selectBy: 'cluster',
            skipGenerateBrief: true,
            skipSaveSlugAndSynopsis: true,
            skipAddInternalLinkSuggestions: true,
            skipResearch: true,
            skipGenerateDraft: true,
            skipEditor: true,
            skipPublish: false,
            publishTo: this.activeContentplan.main_cms_integration || 'autoblog',
            userType: 'managed'
          }
        })
      } finally {
        this.isLoading.upload = null
      }
    },
    toggleSelectArticle (article) {
      const index = this.selectedArticles.findIndex(a => a.article_id === article.article_id)
      if (index === -1) this.selectedArticles.push(article)
      else this.selectedArticles.splice(index, 1)
    },
    isArticleSelected (article) {
      return this.selectedArticles.some(a => a.article_id === article.article_id)
    },
    clearSelectedArticles () {
      this.selectedArticles = []
    },
    toggleSelectAllInTopic (groupedArticles) {
      // Check if all articles in this topic are already selected
      const allSelected = this.areAllTopicArticlesSelected(groupedArticles)

      if (allSelected) {
        // If all are selected, deselect all in this topic
        this.selectedArticles = this.selectedArticles.filter(selected => {
          return !groupedArticles.some(article => article.article_id === selected.article_id)
        })
      } else {
        // If not all selected, select all articles in this topic that aren't already selected
        groupedArticles.forEach(article => {
          if (!this.isArticleSelected(article)) {
            this.selectedArticles.push(article)
          }
        })
      }
    },
    areAllTopicArticlesSelected (groupedArticles) {
      if (!groupedArticles || groupedArticles.length === 0) return false
      return groupedArticles.every(article => this.isArticleSelected(article))
    },
    async handleUploadSelected () {
      const articleIds = this.selectedArticles.map(article => article.article_id)
      if (articleIds.length === 0) return

      this.isLoading.upload = true
      try {
        // Run orchestrator
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            clusterIds: articleIds,
            runUpdating: false,
            runGrouping: false,
            runMapping: false,
            runWriting: true,
            selectBy: 'cluster',
            skipGenerateBrief: true,
            skipSaveSlugAndSynopsis: true,
            skipAddInternalLinkSuggestions: true,
            skipResearch: true,
            skipGenerateDraft: true,
            skipEditor: true,
            skipPublish: false,
            publishTo: this.activeContentplan.main_cms_integration || 'autoblog',
            userType: 'managed'
          }
        })
        this.clearSelectedArticles()
      } finally {
        this.isLoading.upload = false
      }
    },
    async handleRejectSelected () {
      const articleIds = this.selectedArticles.map(article => article.article_id)
      if (articleIds.length === 0) return

      this.isLoading.reject = true
      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updatePriority',
          articleIds,
          priority: 'low'
        })
        this.clearSelectedArticles()
        await this.loadData()
      } finally {
        this.isLoading.reject = null
      }
    },
    handleSnackbarAction (event) {
      console.log('Snackbar action:', event)
      if (event === 'upload-selected') {
        this.handleUploadSelected()
      } else if (event === 'reject-selected') {
        this.handleRejectSelected()
      } else if (event === 'rewrite-selected') {
        this.openRewriteDialog()
      }
    },
    // Rewrite methods
    openRewriteDialog (article) {
      this.rewriteArticles = article ? [article] : [...this.selectedArticles]
      this.showRewriteDialog = true
      this.showSnackbar = false
    },
    closeRewriteDialog () {
      this.showRewriteDialog = false
      this.rewriteArticles = []
      this.rewriteStatus = null
    },
    async confirmRewrite () {
      if (!this.rewriteArticles.length) return
      this.isLoading.rewrite = true
      try {
        // Set status for articles if status is selected
        if (this.rewriteStatus) {
          await this.updateArticles({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            action: 'updateStatus',
            articleIds: this.rewriteArticles.map(article => article.article_id),
            status: this.rewriteStatus
          })
        }

        // Add progress tracker for each article
        const clusterIds = []
        for (const article of this.rewriteArticles) {
          clusterIds.push(article.article_id)

          // Determine step statuses based on rewriteStatus
          let stepsObject = {}

          // If rewriteStatus is set, use it; otherwise use the article's current status
          const statusToUse = this.rewriteStatus || article.status

          switch (statusToUse) {
            case 'briefed':
              stepsObject = {
                Briefing: 'done',
                Researching: 'inProgress',
                Drafting: 'pending',
                Editing: 'pending'
              }
              break

            case 'researched':
              stepsObject = {
                Briefing: 'done',
                Researching: 'done',
                Drafting: 'inProgress',
                Editing: 'pending'
              }
              break

            case 'drafted':
              stepsObject = {
                Briefing: 'done',
                Researching: 'done',
                Drafting: 'done',
                Editing: 'inProgress'
              }
              break

            case 'no status':
            default:
              stepsObject = {
                Briefing: 'inProgress',
                Researching: 'pending',
                Drafting: 'pending',
                Editing: 'pending'
              }
              break
          }
          this.addProgressTracker({
            contentplanId: this.contentplanId,
            clusterId: article.article_id,
            name: article.title,
            stepsObject
          })
        }
        // Run orchestrator for each article
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            clusterIds,
            runUpdating: false,
            runGrouping: false,
            runMapping: false,
            runWriting: true,
            selectBy: 'cluster',
            publishTo: this.activeContentplan.main_cms_integration || 'autoblog',
            userType: 'managed'
          }
        })

        // Close the dialog
        this.closeRewriteDialog()
        // Clear selected articles
        this.clearSelectedArticles()
      } catch (error) {
        console.error('Error in rewrite:', error)
        // Update the tracker to show an error
        for (const article of this.rewriteArticles) {
          this.updateProgressTracker({
            contentplanId: this.contentplanId,
            clusterId: article.article_id,
            stepName: 'Briefing',
            status: 'error'
          })
        }
      } finally {
        this.isLoading.rewrite = false
      }
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    MarkdownEditor,
    SnackbarAction
  }
}

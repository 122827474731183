import { mapActions, mapGetters } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  name: 'AISettings',
  components: {
    MarkdownEditor
  },
  data () {
    return {
      isLoading: {
        contentplan: false,
        saving: false,
        agent: false
      },
      styleGuide: '',
      aiResponse: '',
      snackbar: {
        show: false,
        text: '',
        color: 'success',
        timeout: 3000
      }
    }
  },
  computed: {
    ...mapGetters([
      'websocketConnected'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    // Use dummy article ID or cluster ID for testing
    clusterId () {
      return 356375
    }
  },
  methods: {
    ...mapActions([
      'sendWebsocketMessage',
      'loadContentplan',
      'updateContentplan'
    ]),
    async loadData () {
      this.isLoading.contentplan = true
      try {
        const contentplan = await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
        console.log(contentplan)
        this.styleGuide = contentplan.style_guide
      } catch (error) {
        console.error('Error loading contentplan:', error)
      }
      this.isLoading.contentplan = false
    },
    async saveStyleGuide () {
      this.isLoading.saving = true

      try {
        // Save to localStorage for persistence
        await this.updateContentplan({
          contentplanId: this.contentplanId,
          style_guide: this.styleGuide
        })

        // Show success message
        this.showSnackbar('Style guide saved successfully', 'success')

        // Automatically run the draft agent to see the results
        await this.runAgent()
      } catch (error) {
        console.error('Error saving style guide:', error)
        this.showSnackbar('Failed to save style guide', 'error')
      } finally {
        this.isLoading.saving = false
      }
    },
    async runAgent () {
      if (!this.styleGuide.trim()) {
        return this.showSnackbar('Please enter a style guide first', 'warning')
      }

      this.isLoading.agent = true
      this.aiResponse = '' // Clear previous response

      try {
        // Send websocket message to call the draft agent
        await this.sendWebsocketMessage({
          action: 'callAgent',
          message: {
            clusterId: this.clusterId,
            agent: 'editor'
          }
        })
      } catch (error) {
        console.error('Error calling draft agent:', error)
        this.aiResponse = 'Something went wrong while generating content. Please try again.'
        this.showSnackbar('Failed to generate content', 'error')
      } finally {
        this.isLoading.agent = false
      }
    },
    showSnackbar (text, color = 'success') {
      this.snackbar.text = text
      this.snackbar.color = color
      this.snackbar.show = true
    },
    handleWebsocketMessage (response) {
      // Handle incoming websocket messages
      if (!response?.messages) return

      // If response is data message, ignore it
      if (response.messages[0]?.role === 'data') return

      // Append to AI response
      this.aiResponse += response.messages[0]?.content || ''
    }
  },
  mounted () {
    this.loadData()
  }
}

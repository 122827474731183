import { mapGetters, mapActions, mapMutations } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'
import ChartComponent from '@/components/Chart/Chart.vue'
import calcMetrics from '@/utils/calcMetrics'
import { alerts, statuses, priorities, funnel, actions } from '@/utils/clusterVariables.js'

export default {
  name: 'Hubs',
  data () {
    return {
      isLoading: {
        excludeKeyword: false,
        resetKeyword: false,
        addTopic: false,
        renameTopic: false,
        excludeTopic: false,
        deleteTopic: false,
        moveKeywords: false,
        addArticle: false,
        moveArticles: false,
        renameArticle: false,
        mergeArticles: false,
        deleteArticles: false,
        resetArticles: false,
        setPriority: false,
        setFunnel: false,
        setStatus: false,
        score: false,
        articles: false,
        keywords: false,
        articleKeywords: false,
        updateTarget: false,
        lockingArticle: false,
        excludePages: false,
        updatePageAction: false,
        articleUrls: false,
        pageArticles: false,
        alerts: false,
        updatePageMergeWith: false,
        similarArticles: false,
        clicksChartData: false,
        counts: false,
        pageKeywords: false,
        createHub: false,
        saveHub: false,
        createHubDialog: false
      },

      // NEW STUFF
      selectedView: 'overview',

      clicksChartData: null,
      clicksChartOptions: {
        scales: {
          yAxes: [{
            type: 'linear',
            position: 'left',
            display: false,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            id: 'y-axis-1',
            min: 0,
            ticks: {
              beginAtZero: true
            },
            stacked: true
          }],
          xAxes: [{
            type: 'time',
            display: false,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            time: {
              unit: 'week',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM DD'
              }
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest'
        },
        legend: {
          display: false
        },
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        }
      },

      hubRankChartOptions: {
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: false,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              beginAtZero: true,
              suggestedMax: 10
            }
          }],
          xAxes: [{
            display: true,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },

      tableExistingArticles: {
        isLoading: false,
        headers: [
          { text: 'URL', value: 'article_url' },
          { text: 'Cluster', value: 'title' },
          { text: 'Action', value: 'suggested_action' },
          { text: 'Search Volume', value: 'estimated_search_volume' },
          { text: 'Cluster Rank', value: 'google_position_last' },
          { text: 'More', value: 'more' }
        ],
        sortBy: 'estimated_search_volume',
        sortDesc: true
      },

      tableNewArticles: {
        isLoading: false,
        headers: [
          { text: 'Cluster', value: 'title' },
          { text: 'Potential Clicks', value: 'potential_clicks' },
          { text: 'More', value: 'more' }
        ],
        sortBy: 'potential_clicks',
        sortDesc: true
      },

      tableArticles: {
        isLoading: false,
        headers: [
          { text: 'Cluster', value: 'title' },
          { text: 'URL', value: 'article_url' },
          { text: 'Search Volume', value: 'estimated_search_volume' },
          { text: 'URL Impressions', value: 'impressions' }
        ],
        sortBy: 'estimated_search_volume',
        descending: true
      },

      tableOptions: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ['estimated_search_volume'],
        sortDesc: [true]
      },

      titleFilter: '',
      topicFilter: null,
      showTopicDetails: false,
      showTopicAdd: false,

      selectedArticles: [],
      compareArticle: null,
      similarArticles: null,

      // OLD STUFF

      selectedTopic: null,
      selectedKeywords: [],
      selectedPages: [],
      selectedKeyword: [],
      selectAllArticles: false,
      selectAllPages: false,

      trending: false,
      losing: false,

      statuses,
      priorities,
      funnel,
      alerts,
      actions,

      metrics: [
        { text: 'Search Volume', value: 'estimated_search_volume', ranges: [0, 100, 1000, 10000] },
        { text: 'Potential Clicks', value: 'potential_clicks', ranges: [0, 100, 1000, 10000] },
        { text: '# of Keywords', value: 'keyword_count', ranges: [0, 10, 30, 100] },
        { text: 'URL Impressions', value: 'page_impressions_last', ranges: [0, 100, 1000, 10000] },
        { text: 'URL Clicks', value: 'page_clicks_last', ranges: [0, 10, 100, 1000] },
        { text: 'URL CTR', value: 'page_ctr_last', ranges: [0, 1, 5, 10] },
        { text: 'URL Rank', value: 'page_position_last', ranges: [1, 3, 10, 30] },
        { text: 'URL Impressions Change', value: 'page_impressions_change', ranges: [-1000, -100, 0, 100, 1000] },
        { text: 'URL Clicks Change', value: 'page_clicks_change', ranges: [-100, -10, 0, 10, 100] },
        { text: 'URL CTR Change', value: 'page_ctr_change', ranges: [-10, -5, 0, 5, 10] },
        { text: 'URL Rank Change', value: 'page_position_change', ranges: [-30, -10, 0, 10, 30] },
        { text: 'Cluster Match Impressions', value: 'target_impressions', ranges: [0, 100, 1000, 10000] },
        { text: 'Cluster Match Clicks', value: 'target_clicks', ranges: [0, 10, 100, 1000] },
        { text: 'Cluster Match CTR', value: 'target_ctr', ranges: [0, 1, 5, 10] },
        { text: 'Cluster Match Rank', value: 'target_position', ranges: [1, 3, 10, 30] },
        { text: 'Cluster Impressions', value: 'google_impressions_last', ranges: [0, 100, 1000, 10000] },
        { text: 'Cluster Clicks', value: 'google_clicks_last', ranges: [0, 10, 100, 1000] },
        { text: 'Cluster CTR', value: 'google_ctr_last', ranges: [0, 1, 5, 10] },
        { text: 'Cluster Rank', value: 'google_position_last', ranges: [1, 3, 10, 30] },
        { text: 'Cluster Impressions Change', value: 'google_impressions_change', ranges: [-1000, -100, 0, 100, 1000] },
        { text: 'Cluster Clicks Change', value: 'google_clicks_change', ranges: [-100, -10, 0, 10, 100] },
        { text: 'Cluster CTR Change', value: 'google_ctr_change', ranges: [-10, -5, 0, 5, 10] },
        { text: 'Cluster Rank Change', value: 'google_position_change', ranges: [-30, -10, 0, 10, 30] },
        { text: 'Cluster Match Rate', value: 'cluster_match_rate', ranges: [0, 10, 30, 100] }
      ],
      dimensions: [
        { text: 'Action', value: 'suggested_action', type: 'select', options: actions },
        { text: 'Status', value: 'status', type: 'select', options: statuses },
        { text: 'Priority', value: 'priority', type: 'select', options: priorities },
        { text: 'Funnel', value: 'funnel', type: 'select', options: funnel },
        { text: 'Publish date', value: 'published_at', type: 'date' },
        { text: 'Status code', value: 'status_code', type: 'select', options: [200, 301, 302, 308, 403, 404, 503] },
        { text: 'Alerts', value: 'alerts', type: 'select-boolean', options: alerts }
      ],

      metricFilters: [
        { text: 'Search Volume', value: 'estimated_search_volume', ranges: [0, 100, 1000, 10000], min: null, max: null },
        { text: 'Potential Clicks', value: 'potential_clicks', ranges: [0, 100, 1000, 10000], min: null, max: null }
      ],
      dimensionFilters: [
        { text: 'Publish date', value: 'published_at', type: 'date', filter: [null, null] },
        { text: 'Priority', value: 'priority', type: 'select', options: priorities, filter: ['high', 'not set'] }
      ],
      textFilters: [
        { text: 'Cluster', value: 'title', filter: '' },
        { text: 'URL', value: 'article_url', filter: '' },
        { text: 'Keyword', value: 'keyword', filter: '' }
      ],

      selectedMetrics: [
        { text: 'Potential Clicks', value: 'potential_clicks' }
      ],
      selectedDimensions: [
      ],
      rules: {
        topicName: value => this.topics?.filter(topic => topic.name === value).length === 0 || 'Topic name already exists.',
        articleName: value => this.articles?.filter(article => article.title === value).length === 0 || 'Article title already exists.'
      },

      topicNewName: null,
      articleNewName: null,
      keywordsNewArticle: null,
      articlesNewTopicId: null,
      articlesNewPriority: null,
      articlesNewFunnel: null,
      articlesNewStatus: null,
      articlesNewPageId: null,
      pageNewArticleId: null,
      articleChangeUrl: null,
      topicAddAndMoveArticle: false,
      articleAddAndMoveKeyword: false,
      existingPages: null,
      existingPagesFiltered: null,
      articlePage: 1,
      articlePageLength: 9,
      articleAddForm: false,
      topicAddForm: false,
      topicSelectedTags: [],
      createHubStep: 1,
      pagesForHub: [],
      mainKeywordTemp: null,
      mostPopularKeywords: null,

      showTopicRename: false,
      showKeywordMove: false,
      showArticleRename: false,
      showArticlePriority: false,
      showArticleFunnel: false,
      showArticleStatus: false,
      showArticleMove: false,
      showArticleAdd: false,
      showTagMenu: null,
      showNameTopic: false,
      showClustersInTopics: false,
      addClustersToTopic: false,

      selectableTargetUrls: [],
      selectableTargetArticles: []
    }
  },
  mixins: [calcMetrics],
  computed: {
    ...mapGetters([
      'topics',
      'topic',
      'score',
      'articles',
      'keywords',
      'activeContentplan',
      'pages',
      'articleUrls',
      'pageArticles',
      'articleCounts',
      'pageKeywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    selectableArticlesForHub () {
      if (!this.articles) return []
      const selectedArticleIds = this.selectedArticles.map(x => x.article_id)
      // copy articles
      const articlesCopy = JSON.parse(JSON.stringify(this.articles))
      const filteredArticles = articlesCopy.filter(a => (a.title?.toLowerCase()?.includes(this.titleFilter?.toLowerCase()) || a.article_url?.includes(this.titleFilter?.toLowerCase())) && !selectedArticleIds?.includes(a.article_id))
      if (this.showClustersInTopics) {
        return filteredArticles
      }

      return filteredArticles.filter(a => !a.topic_id)
    },
    selectableArticlesForMove () {
      if (!this.articles) return

      return this.articlesData.map(a => ({ text: a.title, value: a.article_id }))
    },
    snackbarKeywords () {
      return this.selectedKeywords?.length > 0
    },
    snackbarArticles () {
      return this.selectedArticles?.length > 0
    },
    snackbarPages () {
      return this.selectedPages?.length > 0
    },
    displayOverlap () {
      return this.selectedKeywords?.length > 1 || this.selectedArticles?.length > 0 || this.selectedPages?.length > 0
    },
    selectableTopics () {
      if (!this.topics) return

      return this.topics.map(a => ({ text: a.name, value: a.id, count: a.article_count })).filter(a => (a.value !== this.topicFilter))
    },
    activeFilterCount () {
      let count = 0

      if (this.titleFilter) count++
      if (this.keywordFilter) count++
      if (this.urlFilter) count++
      if (this.volumeFilter[0] || this.volumeFilter[1]) count++
      if (this.positionFilter[0] || this.positionFilter[1]) count++
      if (this.ctrFilter[0] || this.ctrFilter[1]) count++
      if (this.potentialFilter[0] || this.potentialFilter[1]) count++
      if (this.statusFilter.length) count++
      if (this.priorityFilter.length) count++
      if (this.funnelFilter.length) count++
      if (this.alertsFilter.length) count++

      return count
    },
    createFiltersObject () {
      const filters = {
        contentplanId: this.contentplanId,
        filter_contentplanId: this.contentplanId,
        offset: this.selectedView === 'grid' ? 0 : (this.itemsPage - 1) * this.itemsPerPage,
        limit: this.itemsPage * this.itemsPerPage,
        orderByColumn: this.sortBy,
        orderByDirection: this.sortDesc
      }

      // Process metric filters
      this.metricFilters.forEach((f) => {
        if (f.min !== null) {
          filters[`filter_min_${f.value}`] = parseInt(f.min)
        }
        if (f.max !== null) {
          filters[`filter_max_${f.value}`] = parseInt(f.max)
        }
      })

      // Process dimension filters
      this.dimensionFilters.forEach((f) => {
        if (f.type === 'select') {
          filters[`filter_${f.value}`] = f.filter
        }
        if (f.type === 'date') {
          if (f.filter[0]) {
            filters[`filter_min_${f.value}`] = f.filter[0]
          }
          if (f.filter[1]) {
            filters[`filter_max_${f.value}`] = f.filter[1]
          }
        }
      })

      // Process text filters
      this.textFilters.forEach((f) => {
        if (f.filter) {
          filters[`filter_${f.value}`] = f.filter
        }
      })

      if (this.topicFilter) {
        filters.filter_topicId = this.topicFilter.id
      }

      if (this.actionFilter) {
        filters.filter_action = this.actionFilter
      }

      if (this.newOrExistingFilter) {
        if (this.newOrExistingFilter === 'new') {
          filters.filter_new = true
        } else {
          filters.filter_existing = true
        }
      }

      return filters
    },
    itemsPerPage () {
      if (this.selectedView === 'grid') return this.articlePageLength

      return this.tableOptions.itemsPerPage
    },
    itemsPage () {
      if (this.selectedView === 'grid') return this.articlePage

      return this.tableOptions.page
    },
    sortBy () {
      if (this.selectedView === 'grid') return this.selectedMetrics[0].value

      return this.tableOptions.sortBy[0] || 'estimated_search_volume'
    },
    sortDesc () {
      if (this.selectedView === 'grid') return 'desc'

      return this.tableOptions.sortDesc[0] ? 'desc' : 'asc'
    },
    foundKeywordCount () {
      if (!this.keywords?.keywordCounts) return 0

      return this.keywords.keywordCounts.filteredCount
    },
    topicTagsChanged () {
      if (!this.topicSelectedTags) return false

      // Output true if this.topicSelectedTags array is not equal to this.topicFilter.tags array (order doesnt matter)
      const setA = new Set(this.topicSelectedTags)
      const setB = new Set([this.topicFilter.tags])

      const isSetAEqualToB = (a, b) => {
        if (a.size !== b.size) return false
        for (const elem of a) if (!b.has(elem)) return false
        return true
      }

      return !isSetAEqualToB(setA, setB)
    },
    topWords () {
      if (!this.mostPopularKeywords) return []

      const result = this.mostPopularKeywords.filter(k => !this.topicSelectedTags.includes(k))
      return result ? result.slice(0, 10) : []
    },
    newPagesCount () {
      if (!this.articleCounts?.pageCounts) return
      return this.articleCounts.pageCounts.find(x => x.name === 'new')?.count || 0
    },
    existingPagesCount () {
      if (!this.articleCounts?.pageCounts) return
      return this.articleCounts.pageCounts.find(x => x.name === 'existing')?.count || 0
    },
    topicTotalCount () {
      if (!this.articleCounts?.topicCounts) return
      return this.articleCounts.topicCounts.map(x => x.count).reduce((a, b) => a + b, 0)
    },
    totalCount () {
      return this.newPagesCount + this.existingPagesCount
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async tableOptions () {
      await this.mutateArticles()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadTopics',
      'loadArticlesAdvanced',
      'loadScoreAndCommit',
      'loadArticleKeywords',
      'loadPageArticles',
      'loadArticleUrls',
      'loadArticlesByArticle',
      'excludeKeywords',
      'moveKeywords',
      'updatePages',
      'addTopic',
      'updateTopics',
      'updateArticles',
      'addArticle',
      'analyzeLandingPage',
      'recalculateAlerts',
      'getGSCDataAndReturn',
      'loadContentplanKeywords',
      'updateKeywords',
      'startCrawl',
      'loadPagesByKeywords',
      'loadPageKeywords',
      'getMostPopularKeywords'
    ]),

    ...mapMutations([
      'topicNew',
      'topicRename',
      'topicExclude',
      'keywordMoveCluster',
      'keywordExclude',
      'articleExclude',
      'articleMove',
      'articleChangeTag',
      'articleNew',
      'pageExclude',
      'pageMatchLock',
      'pageMatchUpdate'
    ]),

    // RENDER DATA
    async loadData () {
      try {
        this.isLoading.articles = true
        this.articlePage = 1
        await Promise.all([
          this.loadTopics({
            contentplanId: this.contentplanId
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.articles = false
      }
    },
    // NEW STUFF
    topicClusters (topicId) {
      if (!this.articles) return []

      return this.articles.filter(a => a.topic_id === topicId)
    },
    topicClustersTop3 (topicId) {
      if (!this.articles) return []

      return this.articles.filter(a => a.topic_id === topicId).slice(0, 3)
    },
    topicClustersExisting () {
      if (!this.articles) return []

      const tempArticles = this.articles
      return tempArticles.filter(a => a.article_url)
    },
    topicClustersNew () {
      if (!this.articles) return []

      const tempArticles = this.articles
      return tempArticles.filter(a => !a.article_url)
    },
    topicName (topicId) {
      if (!this.topics) return

      const topic = this.topics.find(t => t.id === topicId)
      return topic?.name
    },
    async handleClusterSelect (article) {
      const index = this.selectedArticles.findIndex(el => el.article_id === article.article_id)
      if (index === -1) {
        this.selectedArticles.push(article)
      } else {
        this.selectedArticles.splice(index, 1)
      }
    },
    async handleClusterSelectAll () {
      this.selectableArticlesForHub.forEach(article => {
        this.selectedArticles.push(article)
      })
    },
    topicRankChartData (topicId) {
      if (!this.articles) return

      const topicClusters = this.topicClusters(topicId)
      const labels = ['Top 3', '4-10', '11-30', '30-100', 'N/a']
      const backgroundColor = ['#006400', '#228B22', '#32CD32', '#90EE90']

      const data = [
        topicClusters.filter(a => a.google_position_last <= 3)?.length || 0,
        topicClusters.filter(a => a.google_position_last > 3 && a.google_position_last <= 10)?.length || 0,
        topicClusters.filter(a => a.google_position_last > 10 && a.google_position_last <= 30)?.length || 0,
        topicClusters.filter(a => a.google_position_last > 30 && a.google_position_last <= 100)?.length || 0,
        topicClusters.filter(a => typeof a.google_position_last !== 'number')?.length || 0
      ]

      return {
        labels,
        datasets: [{
          backgroundColor,
          data
        }]
      }
    },
    topicActionChartData () {
      if (!this.articleCounts?.pageCounts) return
      const labels = this.actions.map(a => a.text)
      const backgroundColor = ['#c8e6c9', '#ffcdd2', '#ffe0b2', '#bbdefb', '#e1bee7', '#f8bbd0']

      const data = this.articleCounts.pageCounts.filter(x => x.name !== 'existing' && x.name !== 'new').map(x => x.count)

      return {
        labels,
        datasets: [{
          backgroundColor,
          data
        }]
      }
    },
    topicRankChange (topicId) {
      if (!this.topics) return

      const topic = this.topics.find(t => t.id === topicId)
      return parseData((topic.google_position_last - topic.google_position_prev) / topic.google_position_last * 100)
    },
    async getSimilarArticles (articleId) {
      this.isLoading.similarArticles = true
      try {
        this.similarArticles = await this.loadArticlesByArticle({
          contentplanId: this.contentplanId,
          articleId
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.similarArticles = false
      }
    },
    async handleCompareArticleClick (article) {
      if (this.compareArticle === article.article_id) {
        this.compareArticle = null
        this.similarArticles = []
      } else {
        this.compareArticle = article.article_id
        this.getSimilarArticles(article.article_id)
      }
    },
    async filterTopic (topic) {
      this.isLoading.articles = true
      this.topicFilter = topic
      this.showTopicDetails = true

      await Promise.all([
        this.loadArticlesAdvanced({
          contentplanId: this.contentplanId,
          filter_contentplanId: this.contentplanId,
          filter_topicId: topic.id,
          orderByColumn: 'potential_clicks',
          orderByDirection: 'desc'
        }),
        this.getChartDataPotential(topic.id)
      ])

      this.isLoading.articles = false
    },
    async getChartDataPotential (topicId) {
      this.isLoading.clicksChartData = true
      this.clicksChartData = null
      // date is 12 weeks up to now
      this.setDates(12)

      let gscDataArray = [{
        label: 'Cluster Clicks',
        backgroundColor: '#eae4f7',
        borderColor: '#5e35b1',
        fill: true,
        data: null,
        yAxisID: 'y-axis-1'
      }]

      try {
        gscDataArray = await Promise.all(gscDataArray.map(async obj => {
          obj.data = await this.getGSCDataAndReturn({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            topicIds: [topicId]
          })
          return obj
        }))

        if (gscDataArray.some(obj => typeof obj === 'string')) {
          console.log('Error', gscDataArray)
          this.isLoading.clicksChartData = false
          return
        }

        gscDataArray.forEach(obj => {
          // potential clicks data from separate function
          this.calcPotentialClicksForChart(obj.data)
          // clicks data here
          const dateArray = obj?.data.map(x => x.keys[0])
          obj.data = obj?.data.map(x => x.clicks)
          this.calcChartData(dateArray, obj, 'clicksChartData')
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.clicksChartData = false
    },
    calcPotentialClicksForChart (gscData) {
      if (!gscData) return

      const potentialClicksDataset = gscData?.map(x => {
        const estimatedVolume = x.impressions * (x.position > 10 ? x.position / 10 : 1)
        return {
          keys: x.keys,
          potentialClicks: parseData(estimatedVolume * (x.ctr + (Math.sqrt((x.position - 1) * 0.003))) - (x.clicks))
        }
      })

      const dateArray = potentialClicksDataset.map(x => x.keys[0])
      this.calcChartData(dateArray, {
        label: 'Potential Additional Clicks',
        backgroundColor: '#fef8e3',
        borderColor: 'orange',
        fill: true,
        data: potentialClicksDataset.map(x => x.potentialClicks),
        yAxisID: 'y-axis-1'
      }, 'clicksChartData')
    },
    async calcChartData (labels, dataset, target) {
      if (!this[target]) {
        this[target] = {
          labels,
          datasets: [dataset]
        }
      } else {
        this[target].datasets.push(dataset)
      }

      this[target].datasets.sort((a, b) => a.data.reduce((a, b) => a + b, 0) - b.data.reduce((a, b) => a + b, 0))
    },
    getLastSunday (date) {
      // Clone the date object so we don't modify the original
      const d = new Date(date)
      // Get the day of the week (begins on Monday)
      const day = d.getDay()
      // Subtract the day of the week from the date to get the last Sunday
      d.setDate(d.getDate() - day)
      // Return the date as a string in the format "YYYY-MM-DD"
      return d
    },
    setDates (weeks) {
      const today = new Date()
      // take 3 days back (GSC data is 3 days delayed)
      today.setDate(today.getDate() - 3)
      const endDate = this.getLastSunday(today)
      const startDate = new Date()
      startDate.setDate(endDate.getDate() - (weeks * 7) + 1)
      this.date = [startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10)]
    },
    showClustersInTopicsSwitch () {
      if (this.showClustersInTopics) {
        this.tableArticles.headers = [
          { text: 'Cluster', value: 'title' },
          { text: 'URL', value: 'article_url' },
          { text: 'Hub', value: 'topic_id' },
          { text: 'Search Volume', value: 'estimated_search_volume' },
          { text: 'URL Impressions', value: 'impressions' }
        ]
      } else {
        this.tableArticles.headers = [
          { text: 'Cluster', value: 'title' },
          { text: 'URL', value: 'article_url' },
          { text: 'Search Volume', value: 'estimated_search_volume' },
          { text: 'URL Impressions', value: 'impressions' }
        ]
      }
    },
    addTag (tag) {
      this.topicSelectedTags.push(tag.toLowerCase())
      this.titleFilter = ''
      this.getCountKeywords(this.topicSelectedTags)
    },
    removeTag (tag) {
      this.topicSelectedTags = this.topicSelectedTags.filter(t => t !== tag)
      this.getCountKeywords(this.topicSelectedTags)
    },
    async getCountKeywords (tags) {
      if (!tags?.length) return
      this.isLoading.counts = true
      // create regexp out of tags
      const tagsString = tags.join('|')

      await this.loadContentplanKeywords({
        workspaceId: this.workspaceId,
        contentplanId: this.contentplanId,
        q: tagsString,
        limit: 100,
        page: 1,
        url: '',
        status: ['in_plan', 'not_in_plan'],
        source: ['google_search_console', 'upload', 'related'],
        sortColumn: 'estimated_search_volume',
        sortDirection: 'desc'
      })

      this.isLoading.counts = false
    },
    async createHubStep2 (tags) {
      this.createHubStep = 2
      this.isLoading.pages = true
      const tagsString = tags.join('|')

      try {
        await this.loadPagesByKeywords({
          contentplanId: this.contentplanId,
          keywords: tagsString
        })
      } catch (error) {
        console.log(error)
      }

      // create copy of pages and save to pagesForHub
      this.pagesForHub = JSON.parse(JSON.stringify(this.pages))

      this.isLoading.pages = false
    },
    async handleCreateHubClick () {
      this.isLoading.createHub = true

      try {
        const newTopicId = await this.addNewTopic()
        console.log('new topic id', newTopicId)
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          regex: this.topicSelectedTags.join('|'),
          queue: true
        })
        await this.startCrawl({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicId: newTopicId
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.createHub = false
      location.reload()
    },
    async handleSaveHubClick (topicId) {
      this.isLoading.saveHub = true

      try {
        await this.updateTopicTags(topicId)
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          regex: this.topicSelectedTags.join('|'),
          queue: true
        })
        await this.startCrawl({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicId
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.saveHub = false
      location.reload()
    },
    openEditTopicDialog (topic) {
      this.addClustersToTopic = true
      this.showTopicAdd = true
      this.articlesNewTopicId = topic.id
      this.topicSelectedTags = topic?.tags.split('|')
    },
    async openCreateHubDialog () {
      this.isLoading.createHubDialog = true

      this.mostPopularKeywords = await this.getMostPopularKeywords({
        contentplanId: this.contentplanId
      })

      this.isLoading.createHubDialog = false
    },
    suggestedActionText (action) {
      return this.actions.find(a => a.value === action)?.text
    },

    // OLD STUFF
    async mutateArticles (delay) {
      if (this.isLoading.mutatingArticles) return
      this.isLoading.mutatingArticles = true
      this.tableArticles.isLoading = true
      this.isLoading.articles = true

      try {
        if (delay) {
          setTimeout(async () => {
            await this.loadArticlesAdvanced(this.createFiltersObject)
            this.isLoading.mutatingArticles = false
            this.tableArticles.isLoading = false
            this.isLoading.articles = false
          }, delay)
        } else {
          await this.loadArticlesAdvanced(this.createFiltersObject)
          this.isLoading.mutatingArticles = false
          this.tableArticles.isLoading = false
          this.isLoading.articles = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getScore () {
      if (this.selectedPages.length < 1 && this.selectedArticles.length < 1 && this.selectedKeywords.length < 2) return 'N/a'
      if (this.selectedArticles.length > 10 || this.selectedPages.length > 10) return 0

      this.isLoading.score = true

      await this.loadScoreAndCommit({
        contentplanId: this.contentplanId,
        articleId: this.selectedArticles?.length ? this.selectedArticles?.map(el => el.article_id) : this.selectedPages?.map(el => el.article_id),
        keywordId: this.selectedKeywords.map(el => el.keyword_id)
      })

      this.isLoading.score = false
    },
    getArticleKeywords (articleId) {
      if (!this.keywords || !this.articles) return []

      const tempKeywords = this.keywords

      return tempKeywords.filter(k => k.article_id === articleId)
    },
    actionChipColor (item) {
      if (!item) return
      const action = item.action ? item.action : item.suggested_action
      if (item.action) {
        if (action === 'leave') return 'green lighten-5'
        if (action === 'delete') return 'red lighten-5'
        if (action === 'merge') return 'pink lighten-5'
        if (action === 'update') return 'orange lighten-5'
      }
      if (item.suggested_action) {
        if (action === 'leave') return 'green lighten-4'
        if (action === 'delete') return 'red lighten-4'
        if (action === 'merge') return 'pink lighten-4'
        if (action === 'update') return 'orange lighten-4'
      }

      return 'green lighten-4'
    },

    // QUICK FILTERS
    async toggleTrending () {
      this.trending = !this.trending
      this.selectedMetric = {
        text: 'Cluster Impressions Change',
        value: 'google_impressions_change'
      }
      this.sortTopicArticles()
      this.sortExistingPages()
    },
    async toggleLosing () {
      this.losing = !this.losing
      this.selectedMetric = {
        text: 'Cluster Impressions Change',
        value: 'google_impressions_change'
      }
      this.sortTopicArticles()
      this.sortExistingPages()
    },
    addMetricFilter (metric) {
      metric.min = null
      metric.max = null
      this.metricFilters.push(metric)
    },
    removeMetricFilter (metric) {
      this.metricFilters = this.metricFilters.filter(m => m.value !== metric.value)
      this.mutateArticles()
    },
    addDimensionFilter (dimension) {
      dimension.filter = dimension.type === 'date' ? [null, null] : null
      this.dimensionFilters.push(dimension)
    },
    removeDimensionFilter (dimension) {
      this.dimensionFilters = this.dimensionFilters.filter(d => d.value !== dimension.value)
      this.mutateArticles()
    },

    // ACTIONS - GENERAL
    closeTopicDetailView () {
      this.showTopicAdd = false
      this.showTopicDetails = false
      this.addClustersToTopic = false
      this.showClustersInTopics = false
      this.articlesNewTopicId = null

      this.topicFilter = null
      this.selectedArticles = []
      this.selectedKeywords = []
    },
    async recalculate () {
      this.isLoading.alerts = true
      try {
        await this.recalculateAlerts({
          contentplanId: this.contentplanId
        })
      } catch (e) {
        console.log(e)
      }
      this.isLoading.alerts = 'refresh'
    },

    // ACTIONS - KEYWORDS
    async moveSelectedKeywords (articleId) {
      const moveToId = articleId || this.keywordsNewArticle
      if (this.selectedKeywords?.length === 0 || !moveToId) return

      this.isLoading.moveKeywords = true

      try {
        await this.moveKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordIds: this.selectedKeywords.map(el => el.keyword_id),
          articleId: moveToId
        })
        this.mutateArticles()
        this.selectedKeywords = []

        this.isLoading.moveKeywords = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async excludeSelectedKeywords () {
      if (this.selectedKeywords?.length === 0) return

      this.isLoading.excludeKeyword = true
      const keywordIds = this.selectedKeywords.map(el => el.keyword_id)

      try {
        await this.excludeKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordIds,
          excluded: true
        })
        this.mutateArticles()
        this.selectedKeywords = []

        this.isLoading.excludeKeyword = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async resetKeywords (keywordId) {
      if (!this.selectedKeywords?.length && !keywordId) return

      this.isLoading.resetKeyword = true
      const selectedKeywordIds = this.selectedKeywords.map(el => el.keyword_id)

      const keywordIds = keywordId ? [keywordId] : selectedKeywordIds

      try {
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordIds,
          reset: true
        })
        this.mutateArticles()
        this.selectedKeywords = []

        await this.recalculate()
        this.isLoading.resetKeyword = false
      } catch (e) {
        console.log(e)
      }
    },
    async getPageKeywords (pageId) {
      if (!pageId) return
      this.isLoading.pageKeywords = pageId

      try {
        await this.loadPageKeywords({
          contentplanId: this.contentplanId,
          pageId
        })
      } catch (e) {
        console.log(e)
      }

      this.isLoading.pageKeywords = false
    },

    // ACTIONS - PAGES
    async excludePages (pageId) {
      const pageIds = pageId ? [pageId] : this.selectedArticles?.map(el => el.page_id)
      if (pageIds?.length === 0) return

      this.isLoading.excludePages = true
      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds,
          excluded: true
        })
        this.mutateArticles()

        this.selectedPages = []
        this.selectedArticles = []
        this.isLoading.excludePages = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },

    // ACTIONS - ARTICLES
    async moveSelectedArticles (articleId, topicId) {
      if ((this.selectedArticles?.length === 0 || !this.articlesNewTopicId) && (!articleId || !topicId)) return

      const articlesToMove = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      const newTopic = topicId || this.articlesNewTopicId

      if (!articlesToMove?.length || !newTopic) return
      this.isLoading.moveArticles = articleId || true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateTopic',
          articleIds: articlesToMove,
          topicId: newTopic
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.moveArticles = false
      } catch (e) {
        console.log(e)
      }
    },
    async changeArticleTags (articleId, tag, newValue) {
      if ((this.selectedArticles?.length === 0 && !articleId) || !tag) return

      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      if (!articleIds?.length) return

      let action
      if (tag === 'status') {
        action = 'updateStatus'
      } else if (tag === 'priority') {
        action = 'updatePriority'
      } else if (tag === 'funnel') {
        action = 'updateFunnel'
      }

      this.isLoading.changeArticleTags = articleId || true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action,
          articleIds,
          [tag]: newValue
        })
        // this.articleChangeTag({
        //   articleIds,
        //   tag,
        //   newValue
        // })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.changeArticleTags = false
      } catch (e) {
        console.log(e)
      }
    },
    async lockArticleUrl (articleId) {
      if (this.selectedArticles?.length === 0 && !articleId) return

      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      const pageIds = this.articles.filter(el => articleIds.includes(el.article_id)).map(el => el.page_id)

      this.isLoading.lockingArticle = articleId || true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds,
          lock: true
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.lockingArticle = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async excludeSelectedArticles (articleId) {
      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      if (articleIds?.length === 0) return

      this.isLoading.deleteArticles = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'delete',
          articleIds
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.deleteArticles = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async resetSelectedArticles (articleId) {
      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      if (articleIds?.length === 0) return

      this.isLoading.resetArticles = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'reset',
          articleIds
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.resetArticles = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async addNewArticle () {
      if (!this.articleNewName) return
      this.isLoading.newArticle = true

      try {
        const newArticleId = await this.addArticle({
          contentplanId: this.contentplanId,
          topicId: this.topicFilter || this.topics.map(t => t.id)[0],
          name: this.articleNewName
        })
        if (this.articleAddAndMoveKeyword) {
          await this.moveSelectedKeywords(newArticleId?.id)
        } else {
          this.mutateArticles()
        }
        this.showArticleAdd = false
        this.articleNewName = null
        this.articleAddAndMoveKeyword = false
        this.showKeywordMove = false
        this.selectedKeywords = []
        this.isLoading.newArticle = false
      } catch (e) {
        console.log(e)
      }
    },
    async updateArticleUrl (articleId) {
      if (!this.articlesNewPageId) return
      this.isLoading.updateTarget = true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          articleIds: articleId,
          pageIds: this.articlesNewPageId
        })
        this.mutateArticles()

        if (this.articlesNewPageId !== 'null') {
          await this.analyzeLandingPage({
            pageId: this.articlesNewPageId
          })
        }
        this.selectedArticles = []
        this.articlesNewPageId = null
        this.isLoading.updateTarget = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async updateUrlArticle (pageId) {
      if (!this.pageNewArticleId) return
      this.isLoading.updateTarget = true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          articleIds: this.pageNewArticleId,
          pageIds: pageId
        })
        this.mutateArticles()

        if (pageId !== 'null') {
          await this.analyzeLandingPage({
            pageId
          })
        }
        this.selectedArticles = []
        this.pageNewArticleId = null
        this.isLoading.updateTarget = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },

    // ACTIONS - TOPICS
    async addNewTopic () {
      if (!this.topicNewName) return
      this.isLoading.addTopic = true

      let newTopicId
      try {
        const newTopic = await this.addTopic({
          contentplanId: this.contentplanId,
          name: this.topicNewName,
          tags: this.topicSelectedTags.join('|')
        })
        newTopicId = newTopic?.id
        this.topicNew({
          topicId: newTopicId,
          name: this.topicNewName,
          contentplanId: this.contentplanId,
          tags: this.topicSelectedTags.join('|')
        })
      } catch (e) {
        console.log(e)
      }

      this.topicNewName = null
      this.isLoading.addTopic = false

      return newTopicId
    },
    async renameTopic (topicId) {
      if (!this.topicNewName) return

      this.isLoading.renameTopic = true
      const topicIds = topicId || this.selectedTopic
      if (!topicIds) return

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds,
          name: this.topicNewName,
          action: 'rename'
        })
        this.topicRename({
          topicId: topicIds,
          name: this.topicNewName
        })

        this.selectedTopic = null
        this.showTopicRename = false
        this.topicNewName = null
        this.isLoading.renameTopic = false
      } catch (e) {
        console.log(e)
      }
    },
    async deleteTopic (topicId) {
      const topicIds = topicId || this.selectedTopic
      if (!topicIds) return

      this.isLoading.deleteTopic = true

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds,
          action: 'delete'
        })
        this.topicExclude({
          topicIds: [topicIds]
        })
        this.mutateArticles()

        this.selectedTopic = null
        this.topicFilter = null
        this.isLoading.deleteTopic = false
      } catch (e) {
        console.log(e)
      }
    },
    async updateTopicTags (topicId) {
      if (!this.topicSelectedTags) return

      this.isLoading.updateTopicTags = true
      const topicIds = topicId || this.selectedTopic

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds,
          tags: this.topicSelectedTags.join('|'),
          action: 'updateTags'
        })
      } catch (e) {
        console.log(e)
      }
      this.isLoading.updateTopicTags = false
    },

    // ACTIONS - SELECTION
    async handleCheckboxClick (keyword, article, page) {
      if (keyword) {
        this.selectedArticles = []
        this.selectedPages = []
        const index = this.selectedKeywords.findIndex(el => el.keyword_id === keyword.id)
        if (index === -1) {
          this.selectedKeywords.push(keyword)
        } else {
          this.selectedKeywords.splice(index, 1)
        }
      }
      if (article) {
        this.selectedKeywords = []
        this.selectedPages = []
        const index = this.selectedArticles.findIndex(el => el.article_id === article.article_id)
        if (index === -1) {
          this.selectedArticles.push(article)
        } else {
          this.selectedArticles.splice(index, 1)
        }

        if (this.selectedArticles?.length === 1) {
          this.articleNewName = article.title
        } else {
          this.articleNewName = null
        }
      }
      if (page) {
        this.selectedKeywords = []
        this.selectedArticles = []
        const index = this.selectedPages.findIndex(el => el.page_id === page.page_id)
        if (index === -1) {
          this.selectedPages.push(page)
        } else {
          this.selectedPages.splice(index, 1)
        }
      }

      this.getScore()
    },
    async handleSelectAllClick () {
      if (this.selectAllArticles) {
        this.selectedArticles = this.articles
      } else {
        this.selectedArticles = []
      }

      this.getScore()
    },
    handleExistingPagesClick () {
      if (this.actionFilter) {
        this.actionFilter = ''
        this.mutateArticles()
        return
      }
      this.newOrExistingFilter = this.newOrExistingFilter !== 'existing' ? 'existing' : null
      this.mutateArticles()
    },
    handleNewPagesClick () {
      this.actionFilter = ''
      this.newOrExistingFilter = this.newOrExistingFilter !== 'new' ? 'new' : null
      this.mutateArticles()
    },
    handleMetricFilterChipClick (metric, i) {
      console.log(metric, i)

      // write metric min/max values to this.metricFilters array based on index of ranges
      this.metricFilters.map(m => {
        if (m.value === metric.value) {
          m.min = metric.ranges[i - 1]
          m.max = metric.ranges[i]
        }

        return m
      })

      this.mutateArticles()
    },
    isKeywordSelected (keywordId) {
      return this.selectedKeywords.some(el => el.keyword_id === keywordId)
    },
    isArticleSelected (articleId) {
      return this.selectedArticles.some(el => el.article_id === articleId)
    },
    isPageSelected (pageId) {
      return this.selectedPages.some(el => el.page_id === pageId)
    },
    async loadArticleUrlsSelection (articleId) {
      this.isLoading.articleUrls = true

      await this.loadArticleUrls({
        articleId
      })
      this.selectableTargetUrls = this.articleUrls
      // console.log('this.articleUrls', this.articleUrls)

      // this.selectableTargetUrls = this.articles.filter(u => u.article_id !== articleId)
      // console.log('this.selectableTargetUrls', this.selectableTargetUrls)
      // this.selectableTargetUrls = this.selectableTargetUrls.map(iu => {
      //   const urlWithImpressions = this.articleUrls.filter(u => u.page_id === iu.page_id)
      //   console.log('urlWithImpressions', urlWithImpressions)
      //   if (urlWithImpressions.length > 0) {
      //     return { ...iu, impressions: urlWithImpressions[0].impressions }
      //   }
      //   return { ...iu, impressions: 0 }
      // })

      // this.selectableTargetUrls.sort((a, b) => {
      //   if (a.impressions > b.impressions) return -1
      //   if (a.impressions < b.impressions) return 1
      //   if (a.article_id < b.article_id) return -1
      //   if (a.article_id > b.article_id) return 1
      //   return 0
      // })
      // console.log('this.selectableTargetUrls', this.selectableTargetUrls)

      this.isLoading.articleUrls = false
    },
    async loadPageArticlesSelection (pageId) {
      this.isLoading.pageArticles = true

      await this.loadPageArticles({
        pageId
      })
      this.selectableTargetArticles = this.pageArticles

      // this.selectableTargetArticles = this.articles.map(a => {
      //   const articleWithImpressions = this.pageArticles.filter(p => p.article_id === a.article_id)
      //   if (articleWithImpressions.length > 0) {
      //     return {
      //       ...a,
      //       impressions: articleWithImpressions?.[0]?.impressions
      //     }
      //   }
      //   return { ...a, impressions: 0 }
      // })
      // // this.selectableTargetArticles = this.selectableTargetArticles.filter(a => !a.page_id === pageId)

      // // sort by impressions
      // this.selectableTargetArticles.sort((a, b) => {
      //   if (a.impressions > b.impressions) return -1
      //   if (a.impressions < b.impressions) return 1
      //   if (!a.article_url) return -1
      //   if (a.article_url) return 1
      //   return 0
      // })

      this.isLoading.pageArticles = false
    }
  },
  components: { ChartComponent },
  async mounted () {
    await this.loadData()
    document.title = 'Hubs | ContentGecko'
  }
}

export default {
  name: 'SnackbarAction',

  props: {
    // Controls the visibility of the snackbar
    show: {
      type: Boolean,
      default: false
    },
    // Title text to be displayed in the center
    title: {
      type: String,
      default: ''
    },
    // Whether we have content in a slot
    content: {
      type: Boolean,
      default: false
    },
    // Actions shown on the right side
    // Each action should be an object with:
    // { text, event, loading, disabled }
    actions: {
      type: Array,
      default: () => []
    },
    // Color of the snackbar
    color: {
      type: String,
      default: '#90E8E3'
    },
    // Position at the top
    top: {
      type: Boolean,
      default: true
    },
    // Width of the snackbar
    width: {
      type: String,
      default: '96vw'
    }
  },
  data () {
    return {
      localShow: this.show
    }
  },
  watch: {
    show (newVal) {
      this.localShow = newVal
    },
    localShow (newVal) {
      this.$emit('update:show', newVal)
    }
  },
  methods: {
    // Handle when an action button is clicked
    handleAction (action) {
      if (action.event) {
        this.$emit(action.event, action.payload)
      }
    },
    // Handle cancel button click
    cancel () {
      this.localShow = false
      this.$emit('cancel')
    }
  }
}

import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    websocketUrl: process.env.VUE_APP_WEBSOCKET_URL,
    token: window.localStorage.getItem('jwt'),
    domains: null,
    showSidebar: false,
    workspaces: null,
    activeWorkspace: null,
    contentplans: null,
    activeContentplan: null,
    keywords: null,
    topics: null,
    articles: null,
    pages: null,
    score: null,
    article: null,
    articleCounts: null,
    articleUrls: null,
    articleExternalUrls: null,
    crawl: null,
    gsc: {
      byDate: null,
      byQuery: null,
      byPage: null
    },
    pageArticles: null,
    pageKeywords: null,
    benchmarks: null,
    internalLinks: null,
    integrations: [],
    progressTrackers: [],
    snackbar: {
      open: false,
      text: '',
      type: ''
    },
    websocket: {
      instance: null,
      connected: false,
      reconnectAttempts: 0,
      maxReconnectAttempts: 5,
      messages: []
    },
    workspaceUsers: [],
    autoblogSettings: {}
  },
  getters,
  mutations,
  actions
})

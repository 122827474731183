import { mapActions, mapGetters, mapMutations } from 'vuex'
import ClusterCard from '@/components/ClusterCard/ClusterCard.vue'
import { funnel, types, intents } from '@/utils/clusterVariables.js'

export default {
  name: 'Ideation',
  components: {
    ClusterCard
  },
  data () {
    return {
      isLoading: {
        articles: false,
        writing: false,
        changeTopic: false,
        changeFunnel: false,
        changeIntent: false,
        changeType: false,
        addNewCluster: false,
        keywordIdeas: false
      },
      prompt: '',
      lastSubmittedPrompt: '',
      existingArticles: [],
      existingKeywords: [],
      displayWritePopup: false,
      selectedArticle: null,
      selectedFunnel: null,
      selectedIntent: null,
      selectedType: null,
      selectedTopic: null,
      funnel,
      types,
      intents,
      keywordIdeas: [],
      openPanels: [0] // Default open the first panel
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspace',
      'activeContentplan',
      'articles',
      'topics',
      'websocketConnected' // Add the websocket connection status
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    topicSelection () {
      return this.topics?.map(topic => ({
        text: topic.name,
        value: topic.topic_id
      })) ?? []
    },
    showNewClusterCard () {
      const articleTitles = this.existingArticles?.map(article => article.title)
      return this.lastSubmittedPrompt && !this.isLoading.articles && !articleTitles.includes(this.lastSubmittedPrompt)
    }
  },
  methods: {
    ...mapActions([
      'loadArticlesAdvanced',
      'loadTopics',
      'updateArticles',
      'addArticle',
      'postHubExpand',
      'sendWebsocketMessage'
    ]),
    ...mapMutations([
      'addProgressTracker',
      'updateProgressTracker'
    ]),
    selectKeyword (keyword) {
      this.prompt = keyword
      this.getClusters()
    },
    async loadData () {
      document.title = 'New article | ContentGecko'
      this.isLoading.keywordIdeas = true
      try {
        const result = await Promise.all([
          this.loadTopics({
            contentplanId: this.contentplanId
          }),
          this.postHubExpand({
            contentplanId: this.contentplanId,
            runRelatedKeywords: false,
            saveKeywords: false
          })
        ])
        this.keywordIdeas = result[1]
      } catch (error) {
        console.error('Error loading keyword ideas:', error)
      } finally {
        this.isLoading.keywordIdeas = false
      }
    },
    async getClusters () {
      this.lastSubmittedPrompt = this.prompt
      this.isLoading.articles = true
      this.existingArticles = []
      this.existingKeywords = []

      try {
        const [exactTitleMatches, titleMatches, keywordMatches] = await Promise.all([
          // Search for exact title matches
          this.loadArticlesAdvanced({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filter_title: `^${this.lastSubmittedPrompt}$`,
            offset: 0,
            limit: 10,
            orderByColumn: 'estimated_search_volume',
            orderByDirection: 'desc',
            getKeywords: true,
            straightReturn: true
          }),
          // Search for partial title matches
          this.loadArticlesAdvanced({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filter_title: this.lastSubmittedPrompt,
            offset: 0,
            limit: 10,
            orderByColumn: 'estimated_search_volume',
            orderByDirection: 'desc',
            getKeywords: true,
            straightReturn: true
          }),
          // Search for keyword matches
          this.loadArticlesAdvanced({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filter_keyword: this.lastSubmittedPrompt,
            offset: 0,
            limit: 10,
            orderByColumn: 'estimated_search_volume',
            orderByDirection: 'desc',
            getKeywords: true,
            straightReturn: true
          })
        ])
        await this.calcExistingArticles(exactTitleMatches.articles, titleMatches.articles, keywordMatches.articles)
        await this.calcExistingKeywords(exactTitleMatches.keywords, titleMatches.keywords, keywordMatches.keywords)
      } catch (error) {
        console.error(error)
      }
      this.isLoading.articles = false
    },
    async calcExistingArticles (exactTitleMatchArticles, titleMatchArticles, keywordMatchArticles) {
      // Remove duplicates by article_id and prioritize exact matches, then partial title matches, then keyword matches
      if (!exactTitleMatchArticles && !titleMatchArticles && !keywordMatchArticles) {
        this.existingArticles = []
        return
      }

      const seenArticleIds = new Set()

      // Add exact title matches first
      if (exactTitleMatchArticles) {
        exactTitleMatchArticles.forEach(article => {
          if (!seenArticleIds.has(article.article_id)) {
            this.existingArticles.push({
              ...article,
              exact_match: true
            })
            seenArticleIds.add(article.article_id)
          }
        })
      }

      // Add partial title matches second
      if (titleMatchArticles) {
        titleMatchArticles.forEach(article => {
          if (!seenArticleIds.has(article.article_id)) {
            this.existingArticles.push({
              ...article,
              exact_match: false
            })
            seenArticleIds.add(article.article_id)
          }
        })
      }

      // Then add keyword matches
      if (keywordMatchArticles) {
        keywordMatchArticles.forEach(article => {
          if (!seenArticleIds.has(article.article_id)) {
            this.existingArticles.push({
              ...article,
              exact_match: false
            })
            seenArticleIds.add(article.article_id)
          }
        })
      }
      console.log('existingArticles', this.existingArticles?.length)
    },
    async calcExistingKeywords (exactTitleMatchKeywords, titleMatchKeywords, keywordMatchKeywords) {
      if (!exactTitleMatchKeywords && !titleMatchKeywords && !keywordMatchKeywords) {
        this.existingKeywords = []
        return
      }
      const seenKeywordIds = new Set()

      // Add exact title matches first
      if (exactTitleMatchKeywords) {
        exactTitleMatchKeywords.forEach(keyword => {
          if (!seenKeywordIds.has(keyword.keyword_id)) {
            this.existingKeywords.push(keyword)
            seenKeywordIds.add(keyword.keyword_id)
          }
        })
      }

      // Add partial title matches second
      if (titleMatchKeywords) {
        titleMatchKeywords.forEach(keyword => {
          if (!seenKeywordIds.has(keyword.keyword_id)) {
            this.existingKeywords.push(keyword)
            seenKeywordIds.add(keyword.keyword_id)
          }
        })
      }

      // Then add keyword matches
      if (keywordMatchKeywords) {
        keywordMatchKeywords.forEach(keyword => {
          if (!seenKeywordIds.has(keyword.keyword_id)) {
            this.existingKeywords.push(keyword)
            seenKeywordIds.add(keyword.keyword_id)
          }
        })
      }
      console.log('existingKeywords', this.existingKeywords?.length)
    },
    getArticleKeywords (articleId) {
      if (!this.existingKeywords?.length) return []
      return this.existingKeywords.filter(k => k.article_id === articleId)
    },
    topicIdToName (topicId) {
      const topic = this.topics.find(t => t.topic_id === topicId)
      return topic ? topic.name : null
    },
    openWritePopup (item) {
      this.selectedTopic = item.topic_id
      this.selectedFunnel = item.funnel
      this.selectedIntent = item.intent
      this.selectedType = item.type
      this.displayWritePopup = true
      this.selectedArticle = item
    },
    closeWritePopup () {
      this.prompt = ''
      this.selectedArticle = null
      this.selectedTopic = null
      this.selectedFunnel = null
      this.selectedIntent = null
      this.selectedType = null
      this.displayWritePopup = false
    },
    async changeTopic (articleId, topicId) {
      if (!articleId || !topicId) return
      this.isLoading.changeTopic = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateTopic',
          articleIds: [articleId],
          topicId
        })
      } catch (e) {
        console.log(e)
      }

      this.isLoading.changeTopic = false
    },
    async changeFunnel (articleId, funnel) {
      if (!articleId || !funnel) return
      this.isLoading.changeFunnel = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateFunnel',
          articleIds: articleId,
          funnel
        })
      } catch (e) {
        console.log(e)
      }

      this.isLoading.changeFunnel = false
    },
    async changeIntent (articleId, intent) {
      if (!articleId || !intent) return
      this.isLoading.changeIntent = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateIntent',
          articleIds: articleId,
          intent
        })
      } catch (e) {
        console.log(e)
      }

      this.isLoading.changeIntent = false
    },
    async changeType (articleId, type) {
      if (!articleId || !type) return
      this.isLoading.changeType = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateType',
          articleIds: articleId,
          type
        })
      } catch (e) {
        console.log(e)
      }

      this.isLoading.changeType = false
    },
    async writeArticle (articleId, articleTitle) {
      this.isLoading.writing = true

      // Add progress tracker to store
      this.addProgressTracker({
        contentplanId: this.contentplanId,
        clusterId: articleId,
        name: articleTitle,
        stepsObject: {
          Briefing: 'inProgress',
          Researching: 'pending',
          Drafting: 'pending',
          Editing: 'pending'
        }
      })

      try {
        await Promise.all([
          this.updateArticles({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            action: 'addToMap',
            articleIds: [articleId]
          }),
          this.sendWebsocketMessage({
            action: 'runOrchestrator',
            message: {
              contentplanId: this.contentplanId,
              clusterIds: [articleId],
              runUpdating: false,
              runGrouping: false,
              runMapping: false,
              runWriting: true,
              selectBy: 'cluster',
              publishTo: this.activeContentplan.main_cms_integration || 'autoblog',
              userType: 'managed'
            }
          })
        ])

        this.closeWritePopup()
      } catch (error) {
        console.log(error)
        // Update the tracker to show an error
        this.updateProgressTracker({
          id: articleId,
          stepName: 'Brief',
          status: 'error'
        })
      }

      this.isLoading.writing = false
    },
    async addNewCluster () {
      this.isLoading.addNewCluster = true
      try {
        const newArticle = await this.addArticle({
          contentplanId: this.contentplanId,
          name: this.lastSubmittedPrompt
        })
        this.openWritePopup(newArticle)
      } catch (error) {
        console.log(error)
      }
      this.isLoading.addNewCluster = false
    }
  },
  async mounted () {
    await this.loadData()
  }
}

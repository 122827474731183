import { mapGetters, mapActions, mapMutations } from 'vuex'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'
import AboutSettings from '@/views/AboutSettings/AboutSettings.vue'
import HubsNew from '@/views/HubsNew/HubsNew.vue'
import StyleGuideSettings from '@/views/StyleGuideSettings/StyleGuideSettings.vue'
import CreateArticle from '@/components/CreateArticle/CreateArticle.vue'

export default {
  name: 'CreateContentplan',
  components: {
    AboutSettings,
    HubsNew,
    StyleGuideSettings,
    CreateArticle
  },
  data () {
    return {
      isLoading: {
        domains: false,
        keywords: false,
        saving: false,
        connectGsc: false,
        orchestrator: false,
        hubComplete: false
      },
      name: null,
      country: null,
      language: null,
      gscProperty: null,

      step: 1,
      loading: false,

      noToken: false,
      noAccess: false,
      noDomains: false,
      domains: null,
      countries,
      languages,

      // Flags for step completion
      updatingStarted: false,
      aboutComplete: false,
      hubsComplete: false,
      styleGuideComplete: false,
      firstArticleComplete: false,

      // Component loading states
      aboutComponentLoading: false,
      hubsComponentLoading: false,
      styleGuideComponentLoading: false,
      newArticleComponentLoading: false
    }
  },
  async mounted () {
    await this.loadData()
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    isFormValid () {
      return this.name && this.country && this.language
    },
    gscConnected () {
      if (this.isLoading.domains) return false
      if (this.noToken || this.noAccess) return false
      return this.domains?.length > 0
    }
  },
  methods: {
    ...mapActions([
      'createContentplan',
      'postSearchConsoleIntegration',
      'getSearchConsoleProperties',
      'updateWorkspace',
      'sendWebsocketMessage'
    ]),
    ...mapMutations([
      'addProgressTracker'
    ]),
    async loadData () {
      if (!this.contentplanId) return
      this.isLoading.domains = true
      this.step = 2

      try {
        this.domains = await this.getSearchConsoleProperties()
        if (!this.domains.length) this.noDomains = true
      } catch (err) {
        if (err?.response?.data?.message === 'No refresh token') {
          console.log('No refresh token')
          this.noToken = true
        } else if (err?.response?.data?.message === 'Bad Request' || err?.response?.data?.message?.error?.code === 403) {
          console.log('No access')
          this.noAccess = true
        } else {
          console.log('Error message', err?.response?.data?.message)
        }
      }

      this.isLoading.domains = false
    },
    async saveContentplan () {
      if (!this.name || !this.country || !this.language) return

      this.isLoading.saving = true

      const contentplan = await this.createContentplan({
        workspaceId: this.workspaceId,
        name: this.name,
        country: this.country,
        language: this.language,
        type: 'managed'
      })

      // add contentplanId to route
      this.$router.push({
        name: 'CreateContentplanStep2',
        params: {
          workspaceId: this.workspaceId,
          contentplanId: contentplan.contentplanId
        }
      })

      this.isLoading.saving = false
      this.noToken = true
      this.step = 2
    },
    async saveIntegration () {
      if (!this.gscProperty) return
      this.isLoading.saving = true
      const accessUser = this.domains.find(d => d.domain === this.gscProperty)?.userId
      console.log('Access user:', accessUser)

      try {
        await this.postSearchConsoleIntegration({
          contentplanId: this.contentplanId,
          property: this.gscProperty,
          accessUser: accessUser || null
        })

        // Start the content update process
        this.isLoading.orchestrator = true
        this.updatingStarted = true

        // Add progress tracker for updating contentplan
        this.addProgressTracker({
          contentplanId: this.contentplanId,
          name: 'Updating contentplan',
          stepsObject: {
            Importing: 'inProgress',
            'Checking new pages': 'pending',
            'Page matching': 'pending',
            'Crawling pages': 'pending'
          }
        })

        // Add progress tracker for grouping
        this.addProgressTracker({
          contentplanId: this.contentplanId,
          name: 'Adding keywords to clusters',
          stepsObject: {
            Crawling: 'inProgress',
            Clustering: 'pending',
            Labeling: 'pending',
            'Page matching': 'pending'
          }
        })

        // Send websocket message to run orchestrator
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            runUpdating: true,
            runGrouping: true,
            userType: 'managed'
          }
        })

        this.isLoading.orchestrator = false
        // Move to the About Company step
        this.step = 3
      } catch (err) {
        console.error(err)
      }

      this.isLoading.saving = false
    },
    async handleConnectGscClick () {
      this.isLoading.connectGsc = true
      const nextUrl = window.location.href
      const scope = 'search-console'

      let accessKey
      try {
        const result = await this.updateWorkspace({
          workspaceId: this.workspaceId,
          action: 'generateAccessKey'
        })
        accessKey = result.accessKey
        console.log('Access key:', accessKey)
      } catch (err) {
        console.error(err)
      }

      const params = `next=${nextUrl}&contentplanId=${this.contentplanId}&scope=${scope}&workspaceId=${this.workspaceId}&accessKey=${accessKey}`
      const authUrl = window.location.origin + '/auth/in?' + params
      console.log('Opening URL:', authUrl) // Debugging line to check the authUrl
      window.location.href = authUrl
    },
    handleAboutLoading (loading) {
      this.aboutComponentLoading = loading
    },
    handleHubsLoading (loading) {
      this.hubsComponentLoading = loading
    },
    handleStyleGuideLoading (loading) {
      this.styleGuideComponentLoading = loading
    },
    handleNewArticleLoading (loading) {
      this.newArticleComponentLoading = loading
    },
    handleArticleComplete (articleData) {
      this.firstArticleComplete = true
      // Show completion message with article link
      this.$nextTick(() => {
        this.$router.push({
          path: articleData.articleUrl,
          query: {
            onboarding: 'complete',
            message: 'Congratulations! Your first article is being created. You can now explore the rest of ContentGecko while we work on it.'
          }
        })
      })
    },
    completeAboutStep () {
      this.aboutComplete = true
      this.step = 4
    },
    async completeHubsStep () {
      this.isLoading.hubComplete = true
      this.addProgressTracker({
        contentplanId: this.contentplanId,
        name: 'Filling funnel map',
        stepsObject: {
          Prioritizing: 'inProgress',
          'Updating hubs': 'pending',
          Shortlisting: 'pending'
        }
      })
      await this.sendWebsocketMessage({
        action: 'runOrchestrator',
        message: {
          contentplanId: this.contentplanId,
          runMapping: true,
          userType: 'managed'
        }
      })
      this.isLoading.hubComplete = false
      this.hubsComplete = true
      this.step = 5
    },
    completeStyleGuideStep () {
      this.styleGuideComplete = true
      this.step = 6 // Move to first article step
    },
    completeFirstArticleStep () {
      this.firstArticleComplete = true
      this.step = 7 // Final completion step
    },
    gotoDashboard () {
      // Navigate to the main contentplan dashboard
      this.$router.push({
        name: 'Home',
        params: {
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        }
      })
    },
    async goToStep (targetStep) {
      // Cannot go to a step that's higher than the current maximum step
      if (targetStep > this.step) return
      // Cannot go back to step below 1
      if (targetStep < 1) return

      // Set the step directly
      this.step = targetStep
    }
  }
}

const { mapMutations } = require('vuex')

export default {
  name: 'ProgressTracker',
  props: {
    name: {
      type: String,
      required: true
    },
    stepsObject: {
      type: Object,
      required: true
    },
    contentplanId: {
      type: String,
      required: true
    },
    clusterId: {
      type: String,
      required: false
    }
  },
  computed: {
    workspaceId () {
      return this.$route.params.workspaceId || 1
    },
    allStepsDone () {
      return Object.values(this.stepsObject).every(step => step === 'done')
    }
  },
  watch: {
    stepsObject: {
      deep: true,
      handler (newVal) {
        this.checkAndUpdateNextStep(newVal)
      }
    },
    allStepsDone (newVal) {
      if (newVal === true) {
        this.$emit('all-steps-completed', {
          contentplanId: this.contentplanId,
          clusterId: this.clusterId
        })
      }
    }
  },
  methods: {
    ...mapMutations([
      'removeProgressTracker',
      'updateProgressTracker'
    ]),
    getStepColor (status) {
      switch (status) {
        case 'done':
          return 'success'
        case 'inProgress':
          return 'primary'
        case 'error':
          return 'error'
        default:
          return 'grey lighten-1'
      }
    },
    closeTracker (clusterId, contentplanId) {
      this.removeProgressTracker({
        clusterId,
        contentplanId
      })
    },
    checkAndUpdateNextStep (stepsObj) {
      if (!this.id) return

      // Get all steps as an array to maintain order
      const steps = Object.keys(stepsObj)

      // Find if any step was just completed
      for (let i = 0; i < steps.length - 1; i++) {
        const currentStep = steps[i]
        const nextStep = steps[i + 1]

        // If current step is 'done' and the next step is not already 'inProgress' or 'done'
        if (stepsObj[currentStep] === 'done' && stepsObj[nextStep] !== 'inProgress' && stepsObj[nextStep] !== 'done') {
          // Update the next step to 'inProgress'
          this.updateProgressTracker({
            id: this.id,
            stepName: nextStep,
            status: 'inProgress'
          })

          // Only update one step at a time
          break
        }
      }
    }
  }
}

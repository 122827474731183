import { mapGetters, mapActions, mapMutations } from 'vuex'
import parseData from '@/utils/parseData'
import languages from '@/utils/_languages.json'
import countries from '@/utils/_countries.json'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  name: 'ContentplanSettings',
  data () {
    return {
      activeTab: 'general',
      tabs: [
        { id: 'general', label: 'General Settings', icon: 'mdi-cog' },
        { id: 'integrations', label: 'Integrations', icon: 'mdi-connection' },
        { id: 'ai', label: 'AI Settings', icon: 'mdi-robot' },
        { id: 'images', label: 'Image Assets', icon: 'mdi-image' },
        { id: 'autoblog', label: 'Autoblog Settings', icon: 'mdi-web' },
        { id: 'users', label: 'Users', icon: 'mdi-account-group' },
        { id: 'bulk-actions', label: 'Bulk Actions', icon: 'mdi-check-all' }
      ],
      isLoading: {
        contentplan: false,
        saving: false,
        inviting: false,
        users: false,
        autoblog: false,
        autoblogSaving: false,
        images: false
      },
      settings: [
        { label: 'Name', id: 'name', value: '', disabled: false },
        { label: 'Target country', id: 'country_crawl', value: '', options: [...countries], disabled: false },
        { label: 'Content language', id: 'language', value: '', options: [...languages], disabled: false },
        {
          label: 'Minimum SERP overlap for clustering',
          id: 'minimum_score',
          value: '',
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          disabled: false
        }
      ],

      textFields: [
        { label: 'About your company', id: 'about', value: '', disabled: false, placeholder: 'What does your company provide, for whom and what makes you different?' },
        { label: 'Style guide', id: 'style_guide', value: '', disabled: false, placeholder: 'What would you like your content to look like? Formatting, tone, etc.' }
      ],

      integrationsAnalytics: [
        { label: 'Google Search Console', id: 'search_console', value: false, disabled: false, route: 'SearchConsoleIntegration' },
        { label: 'Google Analytics', id: 'google_analytics', value: false, disabled: false, route: 'GoogleAnalyticsIntegration' },
        { label: 'Google Ads (coming soon)', id: 'google_ads', value: false, disabled: true, route: '' }
      ],

      integrationsCMS: [
        { label: 'WordPress', id: 'wordpress', value: false, disabled: false, route: 'WordpressIntegration' },
        { label: 'Webflow (coming soon)', id: 'webflow', value: false, disabled: true, route: '' }
      ],
      dialog: false,
      files: {
        lightLogo: null,
        darkLogo: null,
        cssFile: null
      },
      newImage: null,
      images: [],
      imagePreviewDialog: false,
      selectedImageUrl: null,
      newUserEmail: '',
      inviteDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'activeContentplan',
      'activeIntegration',
      'apiUrl',
      'googleAnalyticsIntegration',
      'searchConsoleIntegration',
      'wordpressIntegration',
      'notionIntegration',
      'workspaceUsers',
      'autoblogSettings'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    notionUrl () {
      const redirectUri = encodeURIComponent(`${window.location.origin}/notion-integration`)
      const state = encodeURIComponent(`contentplanId=${this.contentplanId.toString()}`)
      return `https://api.notion.com/v1/oauth/authorize?client_id=170d872b-594c-8000-94e9-0037491c0013&response_type=code&owner=user&redirect_uri=${redirectUri}&state=${state}`
    },
    autoblogMainUrl () {
      return this.autoblogSettings.link_main || ''
    },
    autoblogStagingUrl () {
      return this.autoblogSettings.link_staging || ''
    }
  },
  methods: {
    parseData,
    ...mapActions([
      'loadContentplan',
      'updateContentplan',
      'getIntegrations',
      'uploadImage',
      'getImages',
      'renameImage',
      'deleteImage',
      'sendWebsocketMessage',
      'resetContentplan',
      'postUser',
      'getWorkspaceUsers',
      'postAutoblogIntegration',
      'getAutoblogSettings',
      'updateAutoblogSettings'
    ]),
    ...mapMutations([
      'addProgressTracker'
    ]),
    async loadData () {
      this.isLoading.contentplan = true
      document.title = 'Contentplan settings | ContentGecko'

      try {
        await this.getIntegrations({ contentplanId: this.contentplanId })
        const contentplanData = await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
        this.mapSettings(contentplanData)
        await this.loadAutoblogSettings()
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.contentplan = false
    },
    mapSettings (data) {
      this.settings.forEach(setting => {
        setting.value = data[setting.id]
      })
      this.textFields.forEach(textField => {
        textField.value = data[textField.id] || ''
      })
    },
    async saveSettings () {
      this.isLoading.saving = true

      try {
        const params = this.settings.reduce((acc, item) => {
          acc[item.id] = item.value
          return acc
        }, {})

        // add text fields to params
        params.about = this.textFields[0].value
        params.style_guide = this.textFields[1].value

        await this.updateContentplan({
          contentplanId: this.contentplanId,
          ...params
        })
        await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
      } catch (error) {
        console.error(error)
      }

      this.isLoading.saving = false
    },
    async handleConnect (id) {
      let nextUrl = ''
      let scope = ''

      if (id === 'google_analytics') {
        nextUrl = window.location.href.replace('/settings', '/ga-integration')
        scope = 'all'
      }

      if (id === 'search_console') {
        nextUrl = window.location.href.replace('/settings', '/gsc-integration')
        scope = 'search-console'
      }

      const params = `next=${nextUrl}&contentplanId=${this.contentplanId}&scope=${scope}`
      const authUrl = window.location.origin + '/auth/in?' + params
      window.location.href = authUrl
    },
    async handleImageUpload () {
      if (!this.newImage) return
      this.isLoading.images = true
      const formData = new FormData()
      formData.append('contentplanId', this.contentplanId)
      formData.append('fileName', this.newImage.name)
      formData.append('contentType', this.newImage.type)

      // Convert file to base64
      const reader = new FileReader()
      const fileContent = await new Promise((resolve) => {
        reader.onload = (e) => resolve(e.target.result)
        reader.readAsDataURL(this.newImage)
      })
      formData.append('fileContent', fileContent)

      try {
        await this.uploadImage(formData)
        await this.loadImages()
        this.newImage = null
      } catch (error) {
        console.error('Error uploading image:', error)
        this.isLoading.images = false
      }
    },
    async loadImages () {
      this.isLoading.images = true
      try {
        const images = await this.getImages({
          contentplanId: this.contentplanId
        })
        this.images = images.map(img => ({
          ...img,
          isEditing: false,
          newName: ''
        }))
      } catch (error) {
        console.error('Error loading images:', error)
      }
      this.isLoading.images = false
    },
    previewImage (image) {
      this.selectedImageUrl = image.url
      this.imagePreviewDialog = true
    },
    handleEditClick (image) {
      image.isEditing = true
      image.newName = image.name
    },
    async handleImageRename (image) {
      if (!image.newName || image.newName === image.name) {
        image.isEditing = false
        image.newName = ''
        return
      }

      this.isLoading.images = true
      try {
        await this.renameImage({
          contentplanId: this.contentplanId,
          action: 'rename',
          currentName: image.name,
          newName: image.newName
        })
        await this.loadImages()
      } catch (error) {
        console.error('Error renaming image:', error)
        this.isLoading.images = false
      }
    },
    async handleImageDelete (image) {
      this.isLoading.images = true
      try {
        await this.deleteImage({
          contentplanId: this.contentplanId,
          action: 'delete',
          currentName: image.name
        })
        await this.loadImages()
      } catch (error) {
        console.error('Error deleting image:', error)
        this.isLoading.images = false
      }
    },
    async runMapping () {
      this.isLoading.mapping = true
      try {
        this.addProgressTracker({
          contentplanId: this.contentplanId,
          name: 'Filling funnel map',
          stepsObject: {
            Prioritizing: 'inProgress',
            'Updating hubs': 'pending',
            Shortlisting: 'pending'
          }
        })
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            runMapping: true,
            userType: 'managed'
          }
        })
      } catch (error) {
        console.error('Error mapping contentplan:', error)
      }
      this.isLoading.mapping = false
    },
    async runUpdating () {
      this.isLoading.updating = true
      try {
        this.addProgressTracker({
          contentplanId: this.contentplanId,
          name: 'Updating contentplan',
          stepsObject: {
            Importing: 'inProgress',
            'Checking new pages': 'pending',
            'Page matching': 'pending',
            'Crawling pages': 'pending'
          }
        })
        await this.sendWebsocketMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.contentplanId,
            runUpdating: true,
            userType: 'managed'
          }
        })
      } catch (error) {
        console.error('Error updating contentplan:', error)
      }
      this.isLoading.updating = false
    },
    async updateAutoblog (branch) {
      this.isLoading.autoblog = true
      try {
        await this.postAutoblogIntegration({ contentplanId: this.contentplanId, branch })
      } catch (error) {
        console.error('Error running autoblog:', error)
      }
      this.isLoading.autoblog = false
    },
    async resetClusters () {
      if (!confirm('Are you sure you want to reset all clusters? This action cannot be undone.')) return

      this.isLoading.resetting = true
      try {
        await this.resetContentplan(this.contentplanId)
      } catch (error) {
        console.error('Error resetting clusters:', error)
      }
      this.isLoading.resetting = false
    },
    async loadUsers () {
      this.isLoading.users = true
      try {
        await this.getWorkspaceUsers({ workspaceId: this.workspaceId })
      } catch (error) {
        console.error('Error loading users:', error)
      }
      this.isLoading.users = false
    },
    async inviteUser () {
      if (!this.newUserEmail) return

      this.isLoading.inviting = true
      try {
        await this.postUser({
          email: this.newUserEmail,
          workspaceId: this.workspaceId
        })
        await this.loadUsers()
        this.newUserEmail = ''
        this.inviteDialog = false
      } catch (error) {
        console.error('Error inviting user:', error)
      }
      this.isLoading.inviting = false
    },
    async loadAutoblogSettings () {
      this.isLoading.autoblog = true
      try {
        // Use the Vuex action instead of direct fetch
        await this.getAutoblogSettings({ contentplanId: this.contentplanId })
      } catch (error) {
        console.error('Error loading autoblog settings:', error)
      }
      this.isLoading.autoblog = false
    },
    async saveAutoblogSettings () {
      this.isLoading.autoblogSaving = true
      try {
        // Use the Vuex action instead of direct fetch
        await this.updateAutoblogSettings({
          contentplanId: this.contentplanId,
          settings: this.autoblogSettings
        })
      } catch (error) {
        console.error('Error saving autoblog settings:', error)
      }
      this.isLoading.autoblogSaving = false
    }
  },
  async mounted () {
    await this.loadData()
    if (this.activeTab === 'users') {
      await this.loadUsers()
    } else if (this.activeTab === 'images') {
      await this.loadImages()
    }
  },
  watch: {
    async activeTab (newTab) {
      if (newTab === 'users') {
        await this.loadUsers()
      } else if (newTab === 'images') {
        await this.loadImages()
      }
    }
  },
  components: {
    MarkdownEditor
  }
}

import parseData from '@/utils/parseData'
import { mapActions } from 'vuex'

export default {
  name: 'ClusterCard',
  props: {
    item: {
      type: Object,
      required: true
    },
    workspaceId: {
      type: Number,
      required: true
    },
    contentplanId: {
      type: Number,
      required: true
    },
    selectedMetric: {
      type: Object,
      default: () => ({ text: 'Search Volume', value: 'estimated_search_volume' })
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    keywords: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    selectedKeywords: {
      type: Array,
      default: () => []
    },
    showArticleCheckbox: {
      type: Boolean,
      default: true
    },
    showKeywordCheckbox: {
      type: Boolean,
      default: true
    },
    ctaButton: {
      type: String,
      default: 'add-to-map'
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      writing: null
    }
  },
  computed: {
    isWritten () {
      const writtenStatuses = ['reviewed', 'shared', 'illustrated', 'edited']
      return writtenStatuses.includes(this.item.status)
    },
    isPublished () {
      return this.item.status === 'published' || this.item.article_url !== null
    },
    isMapped () {
      return this.item.show_in_map
    }
  },
  methods: {
    parseData,
    ...mapActions([
      'updateArticle'
    ]),
    handleCheckboxClick () {
      this.$emit('checkbox-click', this.item)
    },
    handleKeywordClick (keyword) {
      this.$emit('keyword-click', keyword)
    },
    isKeywordSelected (keywordId) {
      return this.selectedKeywords.includes(keywordId)
    },
    handleAddToMap () {
      this.$emit('add-to-map', this.item)
    },
    handleWrite (item) {
      this.$emit('write-article', item)
    }
  }
}

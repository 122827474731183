import axios from 'axios'

const createParamsString = (data) => {
  let params = '?'
  for (const key in data) {
    params += `${key}=${encodeURIComponent(data[key])}&`
  }
  return params.slice(0, -1)
}

const setSnackbarHelper = async ({ commit }, data) => {
  commit('setSnackbar', data)
}

const makeAxiosRequestHelper = async ({ getters, commit, urlSlug, method, data, displaySnackbar = true }) => {
  try {
    const { data: response } = await axios({
      method,
      url: `${getters.apiUrl}/${urlSlug}`,
      data,
      ...getters.apiHeader
    })

    return response
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error.error || error.message
    if (displaySnackbar) setSnackbarHelper({ commit }, { open: true, text: errorMessage, type: 'error' })
    throw error
  }
}

export default {
  // Internal Links
  loadInternalLinksByArticle: async ({ getters, commit }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/internal-links/article/${data.articleId}?key=${data.sharingKey || ''}`, getters.apiHeader)
    await commit('updateInternalLinks', response)
  },

  loadInternalLinksByContentplan: async ({ getters, commit }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/internal-links/contentplan/${data.contentplanId}${createParamsString(data)}`, getters.apiHeader)
    await commit('updateInternalLinks', response)
  },

  // Free Clusterer
  startClusterer: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'clusterer',
      method: 'post',
      data
    })

    return response
  },

  getClustererStatus: async ({ getters }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/clusterer?contentplanId=${data.contentplanId}`, getters.apiHeader)
    return response
  },

  clustererSendEmail: async ({ getters }, data) => {
    const response = await axios.post(`${getters.apiUrl}/clusterer/email`, data, getters.apiHeader)
    return response
  },

  planAndKeywords: async ({ getters }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/plan-and-keywords`, data, getters.apiHeader)
    return response
  },

  writerSendEmail: async ({ getters }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/writer/email`, data, getters.apiHeader)
    return response
  },

  // GSC integration
  getGSCDataAndReturn: async ({ getters }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
    return response
  },

  getGSCDataByDate: async ({ getters, commit }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
    commit('updateGSCDataByDate', response)
  },

  getGSCDataByQuery: async ({ getters, commit }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
    commit('updateGSCDataByQuery', response)
  },

  getGSCDataByPage: async ({ getters, commit }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
    commit('updateGSCDataByPage', response)
  },

  // Get about info automatically
  getAboutInfoAuto: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `contentplan/${data.contentplanId}/about-info`,
      method: 'post'
    })
    return response
  },

  // Clustering visualisation
  postHierarchicalClustering: async ({ getters }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/hierarchical-clustering`, data, getters.apiHeader)
    return response
  },

  // Workspaces
  loadWorkspaces: async ({ getters, commit }) => {
    const { data: workspaces } = await axios.get(`${getters.apiUrl}/workspace`, getters.apiHeader)
    await commit('updateWorkspaces', workspaces)
  },

  updateWorkspace: async ({ getters }, data) => {
    const { data: response } = await axios.put(`${getters.apiUrl}/workspace/${data.workspaceId}`, data, getters.apiHeader)
    return response
  },

  // Contentplans
  loadContentplans: async ({ getters, commit }, data) => {
    const { data: contentplans } = await axios.get(`${getters.apiUrl}/workspace/${data.workspaceId}/contentplan${createParamsString(data)}`, getters.apiHeader)
    await commit('updateContentplans', contentplans)
  },

  createContentplan: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `workspace/${data.workspaceId}/contentplan`,
      method: 'post',
      data
    })
    return response
  },

  loadContentplan: async ({ getters, commit }, data) => {
    const { data: contentplan } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}`, getters.apiHeader)
    await commit('updateActiveContentplan', contentplan)
    return contentplan
  },

  updateContentplan: async ({ getters }, data) => {
    await axios.put(`${getters.apiUrl}/workspace/${data.workspaceId}/contentplan`, data, getters.apiHeader)
  },

  resetContentplan: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `contentplan/${data.contentplanId}/reset-clusters`,
      method: 'post',
      data
    })
    return response
  },

  // Keywords
  loadContentplanKeywords: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `contentplan/${data.contentplanId}/keyword${createParamsString(data)}`,
      method: 'get',
      mutationName: 'updateContentplanKeywords'
    })
    if (data.straightReturn) return response
    await commit('updateContentplanKeywords', response)
  },

  loadPageKeywords: async ({ getters, commit }, data) => {
    const { data: keywords } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword/page?pageId=${data.pageId || ''}&key=${data.sharingKey || ''}`, getters.apiHeader)
    await commit('updatePageKeywords', keywords)
  },

  loadArticleKeywords: async ({ getters, commit }, data) => {
    const { data: keywords } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/keyword?key=${data.sharingKey || ''}`, getters.apiHeader)
    if (data.straightReturn) return keywords
    await commit('updateArticleKeywords', keywords)
  },

  getKeyword: async ({ getters }, data) => {
    const { data: keyword } = await axios.get(`${getters.apiUrl}/keyword?keyword=${data.keyword}&contentplanId=${data.contentplanId}`, getters.apiHeader)
    return keyword
  },

  getMostPopularKeywords: async ({ getters }, data) => {
    const { data: keywords } = await axios.get(`${getters.apiUrl}/keyword/popular${createParamsString(data)}`, getters.apiHeader)
    return keywords
  },

  getRelatedKeywords: async ({ getters }, data) => {
    const { data: keywords } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword/related`, data, getters.apiHeader)
    return keywords
  },

  getRelatedKeywordsNlp: async ({ getters }, data) => {
    const { data: keywords } = await axios.post(`${getters.apiUrl}/keyword/related/nlp`, data, getters.apiHeader)
    return keywords
  },

  getRelatedBySerpOverlap: async ({ getters }, data) => {
    const { data: keywords } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword/related${createParamsString(data)}`, getters.apiHeader)
    return keywords
  },

  uploadKeywords: async ({ getters }, data) => {
    await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, data, getters.apiHeader)
  },

  updateKeywords: async ({ getters }, data) => {
    const { data: response } = await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, data, getters.apiHeader)
    return response
  },

  getKeywordsByKeyword: async ({ getters }, data) => {
    const { data: keywords } = await axios.get(`${getters.apiUrl}/keyword/${data.keywordId}/keyword?articleId=${data.articleId || ''}`, getters.apiHeader)
    return keywords
  },

  processKeywords: async ({ getters }, data) => {
    const { data: keywords } = await axios.post(`${getters.apiUrl}/keyword/process`, data, getters.apiHeader)
    return keywords
  },

  postHubExpand: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'contentplan/hub-expand',
      method: 'post',
      data
    })
    return response
  },

  // Pages
  getPage: async ({ getters }, data) => {
    const { data: page } = await axios.get(`${getters.apiUrl}/page?page=${data.page}&contentplanId=${data.contentplanId}`, getters.apiHeader)
    return page
  },

  getPageFromS3: async ({ getters }, data) => {
    const { data: page } = await axios.get(`${getters.apiUrl}/page/s3?pageId=${data.pageId}`, getters.apiHeader)
    return page
  },

  updatePages: async ({ getters }, data) => {
    await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/page`, { pageIds: data.pageIds, excluded: data.excluded, action: data.action, articleIds: data.articleIds, customUrl: data.customUrl, lock: data.lock, mergeWith: data.mergeWith }, getters.apiHeader)
  },

  analyzeLandingPage: async ({ getters }, data) => {
    await axios.get(`${getters.apiUrl}/page/${data.pageId}/analyze`, getters.apiHeader)
  },

  loadPagesByContentplan: async ({ getters, commit }, data) => {
    const { data: pages } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/page${createParamsString(data)}`, getters.apiHeader)
    commit('updatePages', pages)
  },

  loadPagesByArticle: async ({ getters, commit }, data) => {
    const { data: pages } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/page?key=${data.sharingKey || ''}`, getters.apiHeader)
    commit('updatePages', pages)
  },

  loadPagesByKeywords: async ({ getters, commit }, data) => {
    const { data: pages } = await axios.get(`${getters.apiUrl}/page/keywords${createParamsString(data)}`, getters.apiHeader)
    commit('updatePages', pages)
  },

  // Topics
  addTopic: async ({ getters }, data) => {
    const { data: id } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/topic`, data, getters.apiHeader)
    return id
  },

  loadTopics: async ({ getters, commit }, data) => {
    const { data: crawl } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/topic`, getters.apiHeader)
    await commit('loadTopics', crawl)
  },

  updateTopics: async ({ getters }, data) => {
    await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/topic`, data, getters.apiHeader)
  },

  // Articles
  addArticle: async ({ getters }, data) => {
    const { data: id } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/article`, data, getters.apiHeader)
    return id
  },

  loadArticles: async ({ getters, commit }, data) => {
    const { data: articles } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article${createParamsString(data)}`, getters.apiHeader)
    if (data.straightReturn) return articles
    await commit('setArticles', articles)
  },

  loadArticlesContent: async ({ getters, commit }, data) => {
    const { data: articles } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/articles/content${createParamsString(data)}`, getters.apiHeader)
    if (data.straightReturn) return articles
    await commit('setArticles', articles)
  },

  loadArticlesSaas: async ({ getters, commit }, data) => {
    const { data: articles } = await axios.get(`${getters.apiUrl}/workspace/${data.workspaceId}/articles/content${createParamsString(data)}`, getters.apiHeader)
    if (data.straightReturn) return articles
    await commit('setArticles', articles)
  },

  loadArticlesAdvanced: async ({ getters, commit }, data) => {
    const { data: articles } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/advanced${createParamsString(data)}`, getters.apiHeader)
    if (data.straightReturn) return articles
    await commit('loadArticlesAdvanced', articles)
  },

  loadArticle: async ({ getters, commit }, data) => {
    const { data: article } = await axios.get(`${getters.apiUrl}/article/${data.articleId}?key=${data.sharingKey || ''}`, getters.apiHeader)
    await commit('loadArticle', article)
  },

  loadArticleUrls: async ({ getters, commit }, data) => {
    const { data: urls } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/url?key=${data.sharingKey || ''}&empty=${data.empty || ''}`, getters.apiHeader)
    await commit('updateArticleUrls', urls)
  },

  loadArticleExternalUrls: async ({ getters, commit }, data) => {
    const { data: urls } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/url/external?key=${data.sharingKey || ''}`, getters.apiHeader)
    await commit('updateExternalUrls', urls)
  },

  updateArticles: async ({ getters }, data) => {
    await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/article`, data, getters.apiHeader)
  },

  loadArticlesByArticle: async ({ getters }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/${data.articleId}`, getters.apiHeader)
    return response
  },

  loadPageArticles: async ({ getters, commit }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/page/${data.pageId}/article`, getters.apiHeader)
    commit('loadPageArticles', response)
  },

  // Misc
  recalculateAlerts: async ({ getters }, data) => {
    await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/alert`, getters.apiHeader)
  },

  // Misc
  loadScoreAndCommit: async ({ getters, commit }, data) => {
    const { data: score } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/score?articleId=${data.articleId || ''}&keywordId=${data.keywordId || ''}`, getters.apiHeader)
    await commit('updateScore', score)
  },

  loadScoreAndReturn: async ({ getters }, data) => {
    const { data: score } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/score?articleId=${data.articleId || ''}&keywordId=${data.keywordId || ''}`, getters.apiHeader)
    return score
  },

  startCrawl: async ({ getters }, data) => {
    await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/crawl`, data, getters.apiHeader)
  },

  postUser: async ({ getters }, data) => {
    const { data: userId } = await axios.post(`${getters.apiUrl}/user`, data, getters.apiHeader)
    return userId
  },

  getIntegrations: async ({ getters, commit }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/integrations/${data.contentplanId}`, getters.apiHeader)
    await commit('integrations', response)
    return response
  },

  postWordpressIntegration: async ({ getters, commit }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/integrations/wordpress`, data, getters.apiHeader)
    await commit('integrations', response)
    return response
  },

  postNotionIntegration: async ({ getters, commit }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/integrations/notion`, data, getters.apiHeader)
    await commit('integrations', response)
    return response
  },

  getGoogleAnalyticsProperties: async ({ getters }) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/integrations/google-analytics-properties`, getters.apiHeader)
    return response
  },

  saveGoogleAnalyticsProperty: async ({ getters }, data) => {
    const { data: response } = await axios.post(`${getters.apiUrl}/integrations/google-analytics-property`, data, getters.apiHeader)
    return response
  },

  getGoogleAnalyticsData: async ({ getters, commit }, data) => {
    try {
      const { data: response } = await axios.post(`${getters.apiUrl}/integrations/google-analytics-data`, data, getters.apiHeader)
      return response
    } catch (error) {
      const text = error?.response?.data?.message ?? error.message
      setSnackbarHelper({ commit }, { open: true, text, type: 'error' })
      throw error
    }
  },

  getSearchConsoleProperties: async ({ getters, commit }) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'integrations/search-console/properties',
      method: 'get',
      displaySnackbar: true
    })
    return response
  },

  postSearchConsoleIntegration: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'integrations/search-console',
      method: 'post',
      data
    })
    return response
  },

  postAutoblogIntegration: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'integrations/autoblog',
      method: 'post',
      data
    })
    return response
  },

  getLoginUrl: async ({ getters }) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/integrations/cognito/login`, getters.apiHeader)
    return response
  },

  createStripeCheckoutSession: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'integrations/stripe/checkout-session',
      method: 'post',
      data
    })
    return response
  },

  getStripeLink: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `integrations/stripe/portal-link?workspaceId=${data.workspaceId}`,
      method: 'get'
    })
    return response
  },

  initiateCreateContent: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'statemachine/create-content',
      method: 'post',
      data
    })
    setSnackbarHelper({ commit }, { open: true, text: 'Processing...', type: 'success' })
    return response
  },

  // Image Assets
  getImages: async ({ getters }, data) => {
    const { data: response } = await axios.get(`${getters.apiUrl}/images?contentplanId=${data.contentplanId}`, getters.apiHeader)
    return response
  },

  uploadImage: async ({ getters, commit }, data) => {
    try {
      const { data: response } = await axios.post(`${getters.apiUrl}/images`, {
        contentplanId: data.contentplanId,
        fileName: data.fileName,
        fileContent: data.fileContent,
        contentType: data.contentType
      }, getters.apiHeader)

      setSnackbarHelper({ commit }, { open: true, text: 'Image uploaded successfully', type: 'success' })
      return response
    } catch (error) {
      const text = error?.response?.data?.message ?? error.message
      setSnackbarHelper({ commit }, { open: true, text, type: 'error' })
      throw error
    }
  },

  renameImage: async ({ getters, commit }, data) => {
    try {
      const { data: response } = await axios.put(`${getters.apiUrl}/images`, data, getters.apiHeader)

      setSnackbarHelper({ commit }, { open: true, text: 'Image renamed successfully', type: 'success' })
      return response
    } catch (error) {
      const text = error?.response?.data?.message ?? error.message
      setSnackbarHelper({ commit }, { open: true, text, type: 'error' })
      throw error
    }
  },

  deleteImage: async ({ getters, commit }, data) => {
    try {
      const { data: response } = await axios.put(`${getters.apiUrl}/images`, data, getters.apiHeader)

      setSnackbarHelper({ commit }, { open: true, text: 'Image deleted successfully', type: 'success' })
      return response
    } catch (error) {
      const text = error?.response?.data?.message ?? error.message
      setSnackbarHelper({ commit }, { open: true, text, type: 'error' })
      throw error
    }
  },

  // Websocket actions
  initWebsocket: async ({ getters, commit, dispatch }) => {
    // Close existing connection if it exists
    if (getters.websocketInstance) {
      getters.websocketInstance.close()
    }

    // Create new WebSocket connection
    const socket = new WebSocket(getters.websocketUrl)
    commit('setWebsocket', socket)

    // Setup event handlers
    socket.onopen = () => {
      console.log('WebSocket connected')
      commit('setWebsocketConnected', true)
      commit('resetReconnectAttempts')
    }

    socket.onmessage = (event) => {
      const eventData = JSON.parse(event.data)
      console.log('Received websocket message', eventData)
      commit('addWebsocketMessage', eventData)

      // Dispatch a Vuex event that components can listen for
      dispatch('handleWebsocketMessage', eventData)
    }

    socket.onerror = (error) => {
      console.error('WebSocket error: ', error)
    }

    socket.onclose = (event) => {
      console.log('WebSocket closed with code:', event.code)
      commit('setWebsocketConnected', false)

      // Attempt to reconnect if connection was lost unexpectedly
      if (event.code === 1006 &&
          getters.websocketReconnectAttempts < getters.websocketMaxReconnectAttempts) {
        commit('incrementReconnectAttempts')
        const delay = Math.min(1000 * Math.pow(2, getters.websocketReconnectAttempts), 10000)

        setTimeout(() => {
          dispatch('initWebsocket')
        }, delay)
      }
    }
  },

  sendWebsocketMessage: async ({ getters, dispatch }, message) => {
    try {
      // If socket isn't connected, try to connect first
      if (!getters.websocketConnected) {
        await dispatch('initWebsocket')

        // Wait for connection to establish before sending
        await new Promise((resolve) => {
          const checkConnection = () => {
            if (getters.websocketConnected) {
              resolve()
            } else {
              setTimeout(checkConnection, 100)
            }
          }
          checkConnection()
        })
      }

      // Send the message
      getters.websocketInstance.send(JSON.stringify(message))
    } catch (err) {
      console.error('Error sending websocket message', err)
    }
  },

  handleWebsocketMessage: (_, message) => {
    // This is a generic handler that you can customize based on message type
    // Components can subscribe to this action to respond to specific messages
    console.log('Processing websocket message', message)

    // You can add specific handling for different message types here
  },

  closeWebsocket: ({ getters, commit }) => {
    if (getters.websocketInstance) {
      getters.websocketInstance.close()
      commit('setWebsocket', null)
      commit('setWebsocketConnected', false)
    }
  },

  getWorkspaceUsers: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `workspace/user?workspaceId=${data.workspaceId}`,
      method: 'get'
    })
    commit('updateWorkspaceUsers', response)
    return response
  },

  // Expose the setSnackbar function for external use
  setSnackbar: setSnackbarHelper,

  // Get autoblog settings
  getAutoblogSettings: async ({ commit, getters }, { contentplanId }) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `contentplan/${contentplanId}/autoblog-settings`,
      method: 'get'
    })
    commit('setAutoblogSettings', response)
    return response
  },

  // Update autoblog settings
  updateAutoblogSettings: async ({ commit, getters }, { contentplanId, settings }) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: `contentplan/${contentplanId}/autoblog-settings`,
      method: 'put',
      data: settings
    })
    commit('setAutoblogSettings', response)
    return response
  },

  // Create hubs bulk
  createHubsBulk: async ({ getters, commit }, data) => {
    const response = await makeAxiosRequestHelper({
      getters,
      commit,
      urlSlug: 'hubs/bulk-create',
      method: 'post',
      data
    })
    return response
  }
}

import * as d3 from 'd3'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isLoading: {
        resetKeywords: false,
        hierarchicalClustering: false,
        excludeKeywords: false,
        mergeCluster: false,
        similarClusters: false,
        addKeywords: false,
        searchKeywords: false,
        createKeyword: false
      },
      selectedKeywords: [],

      tableArticleKeywords: {
        isLoading: false,
        headers: [
          { text: 'Keywords', value: 'keyword' },
          { text: 'Search Volume', value: 'estimated_search_volume' }
        ],
        sortBy: 'estimated_search_volume',
        descending: true
      },

      showNewArticleModal: false,
      showCreatedArticleModal: false,
      newArticleTitle: '',
      newArticleId: null,

      tabs: ['Add keywords', 'Remove keywords', 'Merge clusters'],
      tabActive: 0,

      similarClusters: [],
      dendrogramData: null,
      keywordSearch: '',
      keywordsToAdd: [],
      selectedKeywordsToAdd: [],
      keywordsSearchResults: [],
      temporaryKeyword: null,
      keywordsSearchOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['estimated_search_volume'],
        sortDesc: [true]
      },
      keywordTableHeaders: [
        { text: 'Keyword', value: 'keyword' },
        { text: 'Search Volume', value: 'estimated_search_volume' },
        { text: 'Cluster', value: 'title' }
      ]
    }
  },
  watch: {
    data: 'drawDendrogram',
    tabActive: {
      immediate: true,
      handler (newTab) {
        this.handleTabChange(newTab)
      }
    },
    keywordsSearchOptions: {
      deep: true,
      handler () {
        if (this.tabActive === 0) {
          this.searchKeywords()
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'activeWorkspace',
      'activeContentplan',
      'keywords'
    ]),
    articleKeywordsForTable () {
      if (Array.isArray(this.keywords)) {
        return this.keywords
      } else if (this.keywords && Array.isArray(this.keywords.keywords)) {
        return this.keywords.keywords
      }
      return []
    },
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    selectedKeywordsCount () {
      return this.selectedKeywords?.length || 0
    },
    selectedKeywordsToAddCount () {
      return this.selectedKeywordsToAdd?.length || 0
    }
  },
  beforeDestroy () {
    this.cleanupDendrogram()
  },
  methods: {
    ...mapActions([
      'updateKeywords',
      'addArticle',
      'updateArticles',
      'postHierarchicalClustering',
      'loadArticlesByArticle',
      'loadArticleKeywords',
      'loadContentplanKeywords',
      'uploadKeywords'
    ]),
    async handleTabChange (tabIndex) {
      if (tabIndex === 0 && this.keywordsSearchResults.length === 0) {
        await this.searchKeywords()
      } else if (tabIndex === 1 && !this.dendrogramData) {
        await this.loadDendrogramData()
      } else if (tabIndex === 2) {
        this.tableArticleKeywords.isLoading = true
        try {
          await this.loadArticleKeywords({ articleId: this.articleId })
          if (!this.similarClusters.length) {
            await this.loadSimilarArticles()
          }
        } catch (error) {
          console.error('Error loading data for Merge tab:', error)
        } finally {
          this.tableArticleKeywords.isLoading = false
        }
      }
    },
    async loadDendrogramData () {
      this.isLoading.hierarchicalClustering = true
      this.dendrogramData = null

      try {
        const response = await this.postHierarchicalClustering({
          articleId: this.articleId
        })
        this.dendrogramData = response
        if (this.tabActive === 1 && this.dendrogramData?.clusters) {
          this.$nextTick(() => {
            this.drawDendrogram(this.dendrogramData.clusters)
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.hierarchicalClustering = false
      }
    },
    drawDendrogram (data) {
      if (!this.$refs.dendrogram) {
        console.warn('Dendrogram ref not found, skipping draw.')
        return
      }
      const vueInstance = this

      this.cleanupDendrogram()

      const root = d3.hierarchy(data, function (d) {
        return d.isLeaf === false ? d.children : null
      }).sum(d => d.height)

      const nodeSpacing = 12
      const totalNodes = root.descendants().length
      const dynamicHeight = totalNodes * nodeSpacing

      const svg = d3.select(this.$refs.dendrogram).append('svg')
        .attr('width', 800)
        .attr('height', dynamicHeight)

      const treeLayout = d3.cluster().size([dynamicHeight - 20, 500])
      treeLayout(root)

      const g = svg.append('g').attr('transform', 'translate(10,10)')

      g.selectAll('.link')
        .data(root.descendants().slice(1))
        .enter().append('path')
        .attr('class', 'link')
        .attr('d', d => `
          M${d.y},${d.x}
          C${d.y},${(d.x + d.parent.x) / 2}
          ${d.parent.y},${(d.x + d.parent.x) / 2}
          ${d.parent.y},${d.parent.x}`)

      const node = g.selectAll('.node')
        .data(root.descendants())
        .enter().append('g')
        .attr('class', d => 'node' + (d.children ? ' node--internal' : ' node--leaf'))
        .attr('transform', d => `translate(${d.y},${d.x})`)

      node.append('circle')
        .attr('r', 7)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('id', d => 'node-' + d.data.index)
        .on('click', function (event, d) {
          const isSelected = d3.select(this).attr('fill') === 'orange'

          if (isSelected) {
            d3.select(this).attr('fill', 'white')
            d.descendants().forEach(descendant => {
              if (descendant.data.keywordId && vueInstance.selectedKeywords.includes(descendant.data.keywordId)) {
                d3.select('#node-' + descendant.data.index).attr('fill', 'white')
                vueInstance.selectedKeywords = vueInstance.selectedKeywords.filter(keywordId => keywordId !== descendant.data.keywordId)
              }
            })
          } else {
            d3.select(this).attr('fill', 'orange')
            d.descendants().forEach(descendant => {
              if (descendant.data.keywordId && !vueInstance.selectedKeywords.includes(descendant.data.keywordId)) {
                d3.select('#node-' + descendant.data.index).attr('fill', 'orange')
                vueInstance.selectedKeywords.push(descendant.data.keywordId)
              }
            })
          }
        })

      node.append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '0.35em')
        .attr('font-size', '0.9em')
        .text(d => (d.data.overlap < 10 ? d.data.overlap : ''))
        .style('pointer-events', 'none')

      node.append('text')
        .attr('dy', -6)
        .attr('x', 10)
        .attr('y', d => d.children ? -10 : 10)
        .style('text-anchor', d => d.children ? 'end' : 'start')
        .text(d => d.data.name ? d.data.name : '')
    },
    async resetKeywords () {
      if (!this.selectedKeywordsCount) return
      this.isLoading.resetKeywords = true

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds: this.selectedKeywords,
          action: 'reset'
        })
        await this.loadDendrogramData()
        this.selectedKeywords = []
      } catch (error) {
        console.error(error)
      }

      this.isLoading.resetKeywords = false
    },
    async excludeKeywords () {
      if (!this.selectedKeywordsCount) return
      this.isLoading.excludeKeywords = true

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds: this.selectedKeywords,
          action: 'exclude'
        })
        await this.loadDendrogramData()
        this.selectedKeywords = []
      } catch (error) {
        console.error(error)
      }

      this.isLoading.excludeKeywords = false
    },
    async moveToNewCluster () {
      if (!this.selectedKeywordsCount) return
      this.isLoading.moveToNewCluster = true

      try {
        this.newArticleId = await this.addNewArticle(this.newArticleTitle)
        await this.moveSelectedKeywords(this.newArticleId, this.selectedKeywords)
        await this.loadDendrogramData()
      } catch (error) {
        console.error(error)
      }

      this.selectedKeywords = []
      this.newArticleTitle = ''
      this.isLoading.moveToNewCluster = false
      this.showNewArticleModal = false
      this.showCreatedArticleModal = true
    },
    async addNewArticle (title) {
      if (!title) return

      let newArticleId
      try {
        const response = await this.addArticle({
          contentplanId: this.contentplanId,
          name: title
        })
        newArticleId = response.id
      } catch (e) {
        console.log(e)
      }

      return newArticleId
    },
    async moveSelectedKeywords (articleId, keywordIds) {
      if (!keywordIds || !articleId) return

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds,
          toArticleId: articleId,
          action: 'move'
        })
      } catch (e) {
        console.log(e)
      }
    },
    closeCreatedArticleModal () {
      this.showCreatedArticleModal = false
      this.newArticleId = null
    },
    async loadSimilarArticles () {
      this.isLoading.similarClusters = true
      const articles = await this.loadArticlesByArticle({
        contentplanId: this.contentplanId,
        articleId: this.articleId
      })
      if (!articles.length) {
        this.similarClusters = []
        this.isLoading.similarClusters = false
        return
      }

      const articlesWithKeywords = await Promise.all(articles.map(async article => {
        const keywords = await this.loadArticleKeywords({
          articleId: article.id,
          straightReturn: true
        })
        return {
          ...article,
          keywords
        }
      }))

      this.similarClusters = articlesWithKeywords
      this.isLoading.similarClusters = false
    },
    async mergeCluster (articleId) {
      this.isLoading.mergeCluster = articleId

      try {
        await this.updateArticles({
          contentplanId: this.contentplanId,
          articleIdsFrom: [articleId],
          articleIdTo: this.articleId,
          action: 'merge'
        })
        await this.loadArticleKeywords({
          articleId: this.articleId
        })
        await this.loadSimilarArticles()
        await this.loadDendrogramData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.mergeCluster = false
    },
    async searchKeywords () {
      if (!this.keywordSearch?.length) return
      this.isLoading.searchKeywords = true
      this.keywordsSearchResults = []
      this.temporaryKeyword = this.keywordSearch

      try {
        const keywordsResponse = await this.loadContentplanKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          q: this.keywordSearch || '.*',
          limit: this.keywordsSearchOptions.itemsPerPage,
          page: this.keywordsSearchOptions.page,
          sortColumn: this.keywordsSearchOptions.sortBy?.[0] || 'estimated_search_volume',
          sortDirection: this.keywordsSearchOptions.sortDesc?.[0] ? 'desc' : 'asc',
          straightReturn: true
        })

        const keywordsData = keywordsResponse?.keywords
        if (!Array.isArray(keywordsData)) {
          this.keywordsSearchResults = []
          return
        }

        this.keywordsSearchResults = keywordsData.map(keyword => ({
          ...keyword,
          isSelectable: !this.checkIfKeywordAlreadyAdded(keyword.article_id)
        }))

        // Check if the search term exactly matches any of the results
        const exactMatch = this.keywordsSearchResults.find(
          kw => kw.keyword.toLowerCase() === this.keywordSearch.toLowerCase()
        )

        // If no exact match and search term is valid, create temporary keyword
        if (!exactMatch && this.keywordSearch && this.keywordSearch.trim() !== '') {
          this.addTemporaryKeyword(this.keywordSearch)
        }
      } catch (error) {
        console.error('Error searching keywords:', error)
        this.keywordsSearchResults = []
      }

      this.isLoading.searchKeywords = false
    },
    addTemporaryKeyword (keyword) {
      // Create a temporary keyword entry
      this.temporaryKeyword = {
        id: 'temp_' + new Date().getTime(), // Temporary ID
        keyword: keyword.trim(),
        estimated_search_volume: 0, // Default search volume
        article_id: null,
        isSelectable: true,
        isTemporary: true
      }

      // Add temporary keyword to the results
      this.keywordsSearchResults.unshift(this.temporaryKeyword)
    },
    async createNewKeyword (keyword) {
      this.isLoading.createKeyword = true

      try {
        // Upload the keyword to create it
        await this.uploadKeywords({
          contentplanId: this.contentplanId,
          keywordsObject: [{
            keyword
          }],
          source: 'upload'
        })

        // Search again to get the created keyword with its real ID
        const keywordsResponse = await this.loadContentplanKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          q: '^' + keyword + '$',
          limit: 1,
          page: 1,
          sortColumn: 'estimated_search_volume',
          sortDirection: 'desc',
          straightReturn: true
        })

        // Find the recently created keyword
        const newKeyword = keywordsResponse?.keywords?.[0]

        return newKeyword
      } catch (error) {
        console.error('Error creating new keyword:', error)
        return null
      } finally {
        this.isLoading.createKeyword = false
      }
    },
    async addSelectedKeywordsToCluster () {
      if (!this.selectedKeywordsToAdd.length) return

      this.isLoading.addKeywords = true

      try {
        // Process temporary keywords first
        const tempKeywords = this.selectedKeywordsToAdd.filter(kw => kw.isTemporary)
        const regularKeywords = this.selectedKeywordsToAdd.filter(kw => !kw.isTemporary)

        // Create new keywords and get their IDs
        const newKeywordIds = []
        for (const tempKeyword of tempKeywords) {
          const createdKeyword = await this.createNewKeyword(tempKeyword.keyword)
          if (createdKeyword && createdKeyword.id) {
            newKeywordIds.push(createdKeyword.id)
          }
        }

        // Regular keywords
        const regularKeywordIds = regularKeywords.map(kw => kw.id)

        // Combine all keyword IDs
        const allKeywordIds = [...regularKeywordIds, ...newKeywordIds].filter(Boolean)

        if (allKeywordIds.length > 0) {
          await this.updateKeywords({
            contentplanId: this.contentplanId,
            keywordIds: allKeywordIds,
            toArticleId: this.articleId,
            action: 'move'
          })
        }

        this.selectedKeywordsToAdd = []
        this.temporaryKeyword = null

        await this.searchKeywords()
      } catch (error) {
        console.error('Error adding keywords to cluster:', error)
      } finally {
        this.isLoading.addKeywords = false
      }
    },
    checkIfKeywordAlreadyAdded (articleId) {
      return articleId === this.articleId
    },
    closeDialog () {
      this.$emit('close-dendrogram')
    },
    cleanupDendrogram () {
      if (this.$refs.dendrogram) {
        d3.select(this.$refs.dendrogram).select('svg').remove()
      }
    }
  }
}

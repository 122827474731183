import { render, staticRenderFns } from "./AboutSettings.pug?vue&type=template&id=b13da782&lang=pug&external"
import script from "./AboutSettings.js?vue&type=script&lang=js&external"
export * from "./AboutSettings.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports